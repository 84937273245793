import React, { Component } from 'react'
import { compose } from 'redux'
import { connectResource } from '../../common/utils/resource'

import ModalTrigger from '../../common/modals/ModalTrigger'
import { Button, ButtonGroup } from 'reactstrap'
import { Popover } from 'antd'

import Table from '../../common/tables/Table'
import Column from '../../common/tables/Column'
import analytics from '../../../helpers/segment'

import CreatePaymentModal from './CreatePaymentModal'
import LegacyCreatePaymentModal from './LegacyCreatePaymentModal'
import { checkTableData, checkPermission, apiToReadable } from '../../common/utils/helpers'
import moment from 'moment-business-days'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'

class AccountsPayable extends Component {
  state = {
    visibleDeletePopupPaymentId: '',
  }

  isRefund = (payment) => ('stripe_transfer_reversal_id' in payment)

  formatPaymentType = (value, item) => {
    if (this.isRefund(item)) {
      return 'Stripe'
    } else {
      return apiToReadable(value)
    }
  }

  formatStatus = (value, item) => {
    if (this.isRefund(item)) {
      if (value === 'succeeded') {
        return 'Refunded'
      } else if (value === 'failed') {
        return 'Unable to Refund'
      } else {
        return 'In Progress'
      }
    } else {
      // TODO: Remove after Reggora Direct is released
      if (item.status === 'blocked_by_reggora') {
        return apiToReadable('unable_to_complete')
      }

      if (item.payment_type === 'stripe') {
        return apiToReadable(item.status)
      } else {
        return apiToReadable(item.manual_status)
      }
    }
  }

  formatAmount = value => (value / 100).toFixed(2)

  formatAmountInParentheses = value =>
    value ? `($${(Math.abs(value) / 100).toFixed(2)})` : `$${this.formatAmount(value)}`

  isNegativeAmount = value => value < 0

  formatGrossAmount = (value, item) => {
    const amount = this.isRefund(item) ? value : item.amount
    return this.isRefund(item) || this.isNegativeAmount(amount)
      ? this.formatAmountInParentheses(amount) : `$${this.formatAmount(amount)}`
  }

  formatProcessingFee = (value, item) =>
    this.isRefund(item) ? `$${this.formatAmount(value)}` : this.formatAmountInParentheses(value)

  formatNetAmount = (value, item) =>
    this.isRefund(item) || this.isNegativeAmount(value)
      ? this.formatAmountInParentheses(value) : `$${this.formatAmount(value)}`

  formatDate = (value, item) => this.isRefund(item) ? checkTableData(item.created_datetime) : checkTableData(value)
  formatDateToBeSent = (value, item) => this.isRefund(item) ? (
    item.status === 'succeeded' ? checkTableData(item.created_datetime) : ''
  ) : checkTableData(value)

  formatActionButtons = (value, item) => {
    const { orderData, refreshOrder, currentUser, paymentEndpoint, flags } = this.props
    const isStripe = item.payment_type === 'stripe'
    const isInstant = moment(item.date_to_be_sent).diff(moment(item.created), 'seconds') < 5
    const createdGreaterThan1Min = moment.utc().diff(moment.utc(item.created), 'seconds') > 60
    const showEditButton = (
      (!isStripe && !value) ||
      (isStripe && isInstant && createdGreaterThan1Min && !value) ||
      (isStripe && !isInstant && !value)
    ) && !this.isRefund(item)

    return <ButtonGroup>
      {showEditButton && <ModalTrigger
        component={flags?.plat2135UseNewSendFundsModal ? CreatePaymentModal : LegacyCreatePaymentModal}
        id={orderData.id}
        refreshOrder={refreshOrder}
        default_description={item.description}
        default_recipient_name={item.recipient_name}
        default_recipient={item.recipient}
        default_check_address={item.recipient_address}
        paymentId={item.id}
        date_to_be_sent={item.date_to_be_sent}
        default_manual_status={item.manual_status}
        default_payment_type={item.payment_type}
        editing={true}
        job_type={orderData.job_type}
        analytics_track_component={analytics.trackComponent}
        analytics_data={currentUser}
        total_payout_due={orderData.payment_summary.total_payout_due}
        payment_amount={item.amount / 100}
        ramp_invoice_number={item.ramp_invoice_number}
      >
        <Button disabled={!checkPermission('payments_accounts_payable_administer')}>Edit</Button>
      </ModalTrigger>}
      {item.payment_type === 'external' && <Popover
        title='Are you sure?'
        trigger='click'
        visible={this.state.visibleDeletePopupPaymentId === item.id}
        onVisibleChange={(visible) => this.setState({ visibleDeletePopupPaymentId: visible ? item.id : '' })}
        content={<ButtonGroup>
          <Button onClick={() => paymentEndpoint.remove({ payment_id: item.id }).then(refreshOrder)}>
            Yes
          </Button>
          <Button style={{ marginLeft: 10 }} onClick={() => this.setState({ visibleDeletePopupPaymentId: '' })}>
            No
          </Button>
        </ButtonGroup>}>
        <Button
          style={{ marginLeft: 10 }}
          disabled={!checkPermission('payments_accounts_payable_administer') || paymentEndpoint.isLoading}>
          Delete
        </Button>
      </Popover>}
    </ButtonGroup>
  }

  shouldDisplaySendFundsButton = () => {
    const { orderData, is_amc_lender } = this.props

    if (
      !checkPermission('payments_accounts_payable_administer') ||
      !orderData.accepted ||
      (orderData.job_type === 'amc' && !is_amc_lender)
    ) {
      return false
    }
    return true
  }

  render() {
    const {
      orderData,
      refreshOrder,
      address,
      is_amc_lender,
      lenderData,
      currentUser,
      flags,
    } = this.props

    let stripe_payout_method = null
    if (lenderData && lenderData.stripe_payout_method) {
      stripe_payout_method = lenderData.stripe_payout_method
    }

    return (
      <div className='card mb-3'>
        <div className='card-body'>
          <h5 className='card-title w-100 d-flex justify-content-between'>Vendor Payouts
            {this.shouldDisplaySendFundsButton() &&
              <ModalTrigger
                component={flags?.plat2135UseNewSendFundsModal ? CreatePaymentModal : LegacyCreatePaymentModal}
                id={orderData.id}
                refreshOrder={refreshOrder}
                default_description={address}
                default_recipient_name={orderData.check_recipient_name}
                default_recipient={orderData.check_recipient}
                default_payment_type={'stripe'}
                date_to_be_sent={orderData.date_to_be_sent}
                default_manual_status={'unpaid'}
                stripe_payout_method={stripe_payout_method}
                job_type={orderData.job_type}
                is_amc_lender={is_amc_lender}
                analytics_track_component={analytics.trackComponent}
                analytics_data={currentUser}
                total_payout_due={orderData.payment_summary.total_payout_due}
              >
                <Button className='order-header-button btn-sm'>Send Funds</Button>
              </ModalTrigger>}
          </h5>
          <Table striped list={{ results: orderData.payments || [] }}>
            <Column
              title='Vendor'
              field='company'
            />
            <Column
              title='Payment Type'
              field='payment_type'
              format={this.formatPaymentType}
            />
            <Column
              title='Status'
              field='status'
              format={this.formatStatus}
            />
            <Column
              title='Gross Amount'
              field='gross_amount_in_cents'
              format={this.formatGrossAmount}
            />
            <Column
              title='Processing Fee'
              field='processing_fee_in_cents'
              format={this.formatProcessingFee}
            />
            <Column
              title='Net Amount'
              field='net_amount_in_cents'
              format={this.formatNetAmount}
            />
            <Column
              title='Created'
              field='created'
              format={this.formatDate}
            />
            <Column
              title='Date To Be Sent'
              field='date_to_be_sent'
              format={this.formatDateToBeSent}
            />
            <Column
              title=''
              field='sent'
              format={this.formatActionButtons}
            />
          </Table>
        </div>
      </div>
    )
  }
}

export default compose(
  connectResource({
    prefetch: false,
    namespace: 'paymentEndpoint',
    endpoint: 'order/payment/:id?',
    successMessage: {
      DELETE: 'Your payment has been deleted.',
    },
    apiVersion: 2,
  }),
  withLDConsumer(),
)(AccountsPayable)
