import {
  Tooltip,
} from 'antd'
import React from 'react'
import { utcToLocal } from '@common/utils/helpers'
import styles from './ResultRuleset.module.css'

/**
 * @param {{
*  ruleset?: {key: string, name: string},
* }} props
* return {JSX.Element}
*/
const RulesetLabel = ({ ruleset }) => {
  if (!ruleset) {
    return null
  }

  return (
    <Tooltip title={ruleset.key} mouseEnterDelay={2}>
      <span className='d-block'><strong>{ruleset.name}</strong></span>
    </Tooltip>
  )
}

/**
 * @param {{
 *  resultRuleset?: {key: string, name: string},
 *  createdDate?: Date
 * }} props
 * return {JSX.Element}
 */
export const ResultRuleset = ({ resultRuleset, createdDate }) => {
  const createdDateFormatted = createdDate ? utcToLocal(createdDate).format('lll') : 'N/A'

  return (
    <>
      <div className={styles.resultRuleset}>
        <RulesetLabel ruleset={resultRuleset} />
        <small className='d-block'>Date Reviewed:</small>
        <span className='d-block'>{createdDateFormatted}</span>
      </div>
    </>
  )
}
