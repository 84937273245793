import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Input, ModalBody, Modal } from 'reactstrap'
import { REQUEST_METHOD } from '@app/api'
import { useApiRequest } from '../../../common/hooks'
import Loader from '../../../common/layout/components/Loader'
import { ellieMaeRequest } from '../../../common/utils/helpers'
import { setSuccessMessage } from '../../../common/utils/resource'
import ModalConfirmation from '../../../common/modals/ModalConfirmation'
import { selectRemainingOrder } from '../../../store/reducers/order/orderSelectors'
import ToggleSwitchInput from '../../../common/forms/inputs/ToggleSwitchInput'

const LOAN_NUMBER_ID = 364
const ADDRESS_FIELD_ID = 11
const CITY_FIELD_ID = 12
const STATE_FIELD_ID = 14
const ZIP_FIELD_ID = 15

const ENCOMPASS_FIELD_IDS = [
  LOAN_NUMBER_ID,
  ADDRESS_FIELD_ID,
  CITY_FIELD_ID,
  STATE_FIELD_ID,
  ZIP_FIELD_ID,
]

const GUID_REGEX =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i

const EncompassLoanTransfer = ({ loanData, onHide, refreshOrder }) => {
  const apiRequest = useApiRequest()
  const dispatch = useDispatch()
  const orderData = useSelector(selectRemainingOrder)
  const lenderId = orderData.lender.id

  const [isProcessing, setIsProcessing] = useState(false)
  const [ellieLonaId, setellieLonaId] = useState('')
  const [errorMessage, setErrorMessage] = useState(null)
  const [isError, setIsError] = useState(false)
  const [transferLoanNumber, setTransferLoanNumber] = useState(null)
  const [isShowConfirmMsg, setIsShowConfirmMsg] = useState(false)
  const [isPushData, setIsPushData] = useState(true)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const isValidGUID = (guid) => GUID_REGEX.test(guid)

  const fetchEllieLoanData = async () => {
    try {
      const endpoint = `loan/${lenderId}/${ellieLonaId}?ellie_field_ids=${ENCOMPASS_FIELD_IDS.join(',')}`
      const encompassData = await ellieMaeRequest(endpoint)
      return encompassData
    } catch {
      return null
    }
  }

  const getPropertyAddress = (address, city, state, zip) =>
    `${address}, ${city}, ${state} - ${zip}`

  const isValidAddress = (ellieLoanData) => {
    const currentAddress = getPropertyAddress(
      loanData.subject_property_address,
      loanData.subject_property_city,
      loanData.subject_property_state,
      loanData.subject_property_zip
    )

    const transferAddress = getPropertyAddress(
      ellieLoanData[ADDRESS_FIELD_ID],
      ellieLoanData[CITY_FIELD_ID],
      ellieLoanData[STATE_FIELD_ID],
      ellieLoanData[ZIP_FIELD_ID]
    )

    if (currentAddress.toLowerCase() !== transferAddress.toLowerCase()) {
      setIsError(true)
      setErrorMessage(
        `Transfer loan's address (${transferAddress}) does not match with the current loan's address.`
      )
      return false
    }
    return true
  }

  const isValidEllieLoanId = (ellieId) => {
    if (ellieId.length !== 36 || !isValidGUID(ellieId)) {
      setIsError(true)
      setErrorMessage("Please enter a valid Encompass loan's GUID.")
      return false
    }

    if (ellieId === loanData.ellie_loan_id) {
      setIsError(true)
      setErrorMessage("You entered the current loan's GUID. Please enter a new loan's GUID")
      return false
    }

    return true
  }

  const onEllieLoanIdChange = (value) => {
    setellieLonaId(value)
    if (isSubmitted) {
      if (isValidEllieLoanId(value)) {
        setIsError(false)
        setErrorMessage(null)
      }
    }
  }

  const onSubmit = async () => {
    setIsSubmitted(true)
    if (!isValidEllieLoanId(ellieLonaId)) {
      return
    }

    setIsProcessing(true)
    const ellieResponse = await fetchEllieLoanData()
    setIsProcessing(false)
    if (!ellieResponse || !ellieResponse.success) {
      setIsError(true)
      setErrorMessage(
        "Unable to fetch loan data from Encompass. Please verify the loan's GUID."
      )
      return
    }
    const ellieLoanData = ellieResponse.content
    setTransferLoanNumber(ellieLoanData[LOAN_NUMBER_ID])

    if (!isValidAddress(ellieLoanData)) {
      return
    }

    setIsError(false)
    setErrorMessage(null)
    setIsShowConfirmMsg(true)
  }

  const onConfirm = async () => {
    setIsProcessing(true)
    const transferData = {
      loan_id: loanData.id,
      transfer_loan_number: transferLoanNumber,
      transfer_ellie_loan_id: ellieLonaId,
    }
    // Update the reggora loan record
    await apiRequest({
      endpoint: 'loans/encompass-loan-transfer',
      method: REQUEST_METHOD.PUT,
      body: transferData,
      successMessage: 'Loan transfer succeeded.',
    })

    // Push data to the new loan file in Encomopass
    if (isPushData) {
      await ellieMaeRequest(`loan/${loanData.id}/export`, 'post')
      dispatch(setSuccessMessage(
        'Initiated syncing, the new loan file will be updated in Encompass shortly.'
      ))
    }

    refreshOrder()
  }

  const loader = isProcessing && <Loader />
  return (
    <>
      {loader}
      {!isProcessing && (
        <>
          <div className="d-flex flex-row justify-content-between mt-4">
            <div className='mt-1' style={{ fontWeight: 'bold' }}>Loan transfer:</div>
            <div className="w-75">
              <Input
                maxLength={36}
                value={ellieLonaId}
                placeholder="Enter new Encompass loan's GUID"
                onChange={(e) => onEllieLoanIdChange(e.target.value)}
              />

              {isError && <div style={{ color: 'red' }}>{errorMessage}</div>}
            </div>
          </div>
          <div className="d-flex flex-row justify-content-between mt-3">
            <div style={{ fontWeight: 'bold' }}>
              Push latest data/documents to the new loan file:
            </div>
            <div>
              <ToggleSwitchInput
                value={isPushData}
                onToggleChange={(value) => setIsPushData(value)}
              ></ToggleSwitchInput>
            </div>
          </div>
          <div className="row justify-content-end">
            <Button className="mr-2 mt-3" onClick={onHide}>
              Cancel
            </Button>

            <Button
              className="mr-3 mt-3"
              data-test="submit-button"
              color="primary"
              disabled={isSubmitted && isError}
              onClick={onSubmit}
            >
              Transfer Loan
            </Button>
          </div>

          <Modal isOpen={isShowConfirmMsg}>
            <ModalBody>
              <ModalConfirmation
                confirmationText={'Are you sure you want to proceed with the loan transfer?'}
                onConfirm={onConfirm}
                onHide={() => setIsShowConfirmMsg(false)}
                confirmBtn="Yes"
                dismissBtn="No"
                textBody={(<>
                  <span>Current Loan #: <strong>{loanData.number}</strong></span>
                  <br/><br/>
                  <span>New Loan #: <strong>{transferLoanNumber}</strong></span>
                </>)}
              />
            </ModalBody>
          </Modal>
        </>
      )}
    </>
  )
}

export default EncompassLoanTransfer
