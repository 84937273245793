import React, { Fragment, useState } from 'react'
import { ModalHeader, ModalBody, Button } from 'reactstrap'
import { ellieMaeRequest } from '../../../common/utils/helpers'
import StopEncompassDataSync from './StopEncompassDataSync'
import EncompassLoanTransfer from './EncompassLoanTransfer'

const ModalEncompassLoanSettings = (props) => {
  const { loanData, permissions, hasOrder } = props
  const [isPushToEncompassInitiated, setIsPushToEncompassInitiated] = useState(false)

  const onPushToEncompass = async () => {
    setIsPushToEncompassInitiated(true)
    await ellieMaeRequest(`loan/${loanData.id}/export`, 'post')
  }

  const stopDataSync = (
    <ModalBody>
      {permissions.encompass_loan_replication &&
        <StopEncompassDataSync
          loanId={loanData.id}
          onHide={props.onHide}
        />}
    </ModalBody>)

  const loanTransfer = permissions.encompass_loan_transfer && loanData.ellie_loan_id && hasOrder && (
    <ModalBody>
      <hr/>
      <EncompassLoanTransfer
        loanData={loanData}
        onHide={props.onHide}
        refreshOrder={props.refreshOrder}
      />
    </ModalBody>)

  const actionsBody = permissions.encompass_manual_loan_push && loanData.ellie_loan_id && (
    <ModalBody>
      <hr/>
      <Button
        onClick={onPushToEncompass}
        disabled={isPushToEncompassInitiated}
      >
        {isPushToEncompassInitiated ? 'Request sent. This may take a few minutes.' : 'Push Latest Data/Documents to Encompass Now'}
      </Button>
    </ModalBody>)

  return (
    <Fragment>
      <ModalHeader toggle={props.onHide}>Encompass Settings</ModalHeader>
      {stopDataSync}
      {loanTransfer}
      {actionsBody}
    </Fragment>
  )
}

export default ModalEncompassLoanSettings
