// Libraries
import React, { Component, Fragment } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import axios from 'axios'
import FileSaver from 'file-saver'
import {
  Alert,
  Nav,
  NavItem,
  NavLink,
  Button,
  Badge,
} from 'reactstrap'

import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  DownloadOutlined,
  ExclamationCircleOutlined,
  ExportOutlined,
  MinusCircleOutlined,
  SelectOutlined,
  SyncOutlined,
  WarningOutlined,
} from '@ant-design/icons'

import { Icon as LegacyIcon } from '@ant-design/compatible'

import { Tag, Table, Tooltip, Popover, Modal, Button as AntdButton } from 'antd'
import moment from 'moment'
import _ from 'lodash'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'

// Common
import { connectResource } from '../../../common/utils/resource'
import ModalTrigger from '../../../common/modals/ModalTrigger'
import {
  utcToLocal,
  checkPermission,
  apiToReadable,
  calculate_adjusted_delay,
  checkLowValuation,
} from '../../../common/utils/helpers'
import Rephrase from '../../../common/utils/Rephrase'
import { updateMinimumOrder } from '../../../store/reducers/order/orderSlice'

// Components
import StopAutoSendAppraisalToBorrowerModal from './StopAutoSendAppraisalToBorrowerModal'
import BulkRevisionModal from './BulkRevisionModal'
import ExternalReviewDownload from './ExternalReviewDownload'
import SubmissionTableEad from './SubmissionTableEad'
import SubmissionTableUcdp from './SubmissionTableUcdp'
import { ReggoraReview, RepurchaseWarranty } from './ReggoraReview'
import SubmissionActions from './SubmissionActions'
import SubmissionDownloadActions from './SubmissionDownloadActions'
import ApproveSubmissionModal from './ReggoraReview/ApproveSubmissionModal'
import AddComment from './ReggoraReview/ResultTable/AddComment'
import { addScore } from '../../../store/reducers/vendorScore/vendorScoreSlice'
import { selectOrderSubmissionReviewRulesetForUser } from '../../../store/reducers/order/orderSelectors'
import { selectSubmissionFiles } from '../../../store/reducers/submissionFiles/submissionFilesSelectors'
import PdfViewModal from '../../../common/widgets/PdfViewModal'
import AntdModalTrigger from '../../../common/modals/antd/ModalTrigger'
import { ReviewNotesButton } from './ReggoraReview/ReviewNotes/ReviewNotesButton'
import RevisionModal from '../RevisionModal'
import { repurchaseWarrantyByStatus, WarrantyStatusIconOutlined } from '../Warranty/OrderWarrantyStatus'
import ReviewEscalationModal from './ReviewEscalationModal'

const downloadFile = (url, fileName) => {
  axios({
    url: url,
    method: 'GET',
    responseType: 'blob', // important
  }).then((response) => {
    FileSaver.saveAs(new Blob([response.data]), fileName)
  })
}

const tabName = {
  EAD: 'ead',
  Review: 'review',
  UCDP: 'ucdp',
  RepurchaseWarranty: 'repurchaseWarranty',
}

const RepurchaseWarrantyTab = ({ onClick, selectedTab, sub }) => {
  const color = repurchaseWarrantyByStatus[sub?.repurchase_warranty?.status] || ''

  const icon = color ? (
    <small className='mr-1' style={{ color }}>
      <WarrantyStatusIconOutlined status={sub?.repurchase_warranty?.status}/>
    </small>
  ) : <small className='mr-1'><ClockCircleOutlined/></small>

  return (
    <NavItem>
      <NavLink
        active={selectedTab === tabName.RepurchaseWarranty}
        className='submission-tab'
        onClick={onClick}
      >
        {icon}
        Reggora Warranty
      </NavLink>
    </NavItem>
  )
}

class SubmissionObject extends Component {
  componentDidUpdate = (prevProps) => {
    const {
      sub,
      submissionReviews,
      submissionFiles,
      reviewNotes,
      orderData,
    } = this.props
    if (sub !== prevProps.sub) {
      // update selectedTab when we load the submission
      this.setState({
        selectedTab: this.initializeSelectedTab(),
      })
    }
    if (submissionReviews !== prevProps.submissionReviews) {
      this.setState({
        selectedTab: this.initializeSelectedTab(),
        showRulesetChangeWarnings: submissionReviews?.count !== null,
      })
    }
    if (reviewNotes !== prevProps.reviewNotes) {
      const notes = Object.entries(reviewNotes)
        .reduce((acc, [key, val]) => {
          // When we take the notes from all submission, should just need to remove the sub.version check below
          if (val.hasNotes && key.includes(orderData.id) && key.includes(sub.version)) {
            acc.push(val.note.body)
          }
          return acc
        }, [])
      const allNotes = notes.join('\n')
      this.setState({
        submissionComment: allNotes,
      })
    }
    if (submissionFiles !== prevProps.submissionFiles) {
      const hasReviewArtifact = this.getReviewArtifact(submissionFiles, sub.version)
      this.setState({ hasReviewArtifact: !!hasReviewArtifact })
      const hasReviewCompReport = this.getReviewCompReport(submissionFiles, sub.version)
      this.setState({ hasReviewCompReport: !!hasReviewCompReport })
    }
  }

  getReviewArtifact = (submissionFiles, submission_version) => {
    return submissionFiles.find((submission_file) => {
      return submission_file.submission_version === submission_version &&
        submission_file.file_type === 'review_artifact'
    })
  }

  getReviewCompReport = (submissionFiles, submission_version) => {
    return submissionFiles.find((submission_file) => {
      return submission_file.submission_version === submission_version &&
        submission_file.file_type === 'review_comp_report'
    })
  }

  downloadReviewArtifact = () => {
    const token = localStorage.getItem('reggora_lender_auth_token')
    const {
      orderData,
      sub,
      submissionFiles,
    } = this.props
    const { id: orderId } = orderData
    const { version: submission_version } = sub

    const reviewArtifact = this.getReviewArtifact(submissionFiles, submission_version)

    if (!reviewArtifact) {
      return
    }

    axios({
      headers: { Authorization: `bearer ${token}`, 'Cache-Control': 'no-cache' },
      url: `${SERVICE_URL}submission-files/v1/${reviewArtifact._id}/file`,
      method: 'GET',
      responseType: 'blob',
    }).then((fileResponse) => {
      FileSaver.saveAs(new Blob([fileResponse.data]), `Review Artifact ${orderId} V${submission_version}.pdf`)
    })
  }

  downloadReviewCompReport = () => {
    const token = localStorage.getItem('reggora_lender_auth_token')
    const {
      orderData,
      sub,
      submissionFiles,
    } = this.props
    const { id: orderId } = orderData
    const { version: submission_version } = sub

    const reviewCompReport = this.getReviewCompReport(submissionFiles, submission_version)

    if (!reviewCompReport) {
      return
    }

    axios({
      headers: { Authorization: `bearer ${token}`, 'Cache-Control': 'no-cache' },
      url: `${SERVICE_URL}submission-files/v1/${reviewCompReport._id}/file`,
      method: 'GET',
      responseType: 'blob',
    }).then((fileResponse) => {
      FileSaver.saveAs(new Blob([fileResponse.data]), `Review Comparables Report ${orderId} V${submission_version}.pdf`)
    })
  }

  downloadDocument = (documentType = null, version = null) => {
    // used to download documents including external review PDF and SSRs
    const {
      orderData,
    } = this.props
    const token = localStorage.getItem('reggora_lender_auth_token')
    const orderID = orderData.id
    axios({
      headers: { Authorization: `bearer ${token}`, 'Cache-Control': 'no-cache' },
      url: `${API2_URL}order/download-submission/${orderID}/${version}/${documentType}`,
      method: 'GET',
      responseType: 'blob',
      params: {},
    }).then((response) => {
      // determine file name from header because backend calculates it
      const contentDisposition = response?.headers?.['content-disposition']
      // contentDisposition will look like "attachment; filename=result.pdf"
      const splitHeader = contentDisposition.split('=')
      let filename = splitHeader[splitHeader.length - 1]
      // remove any double quotes
      filename = filename.replace(/"/g, '')
      FileSaver.saveAs(new Blob([response.data]), filename)
    })
  }

  // check some permissions based on the state of the submission
  canViewReggoraReview = () => {
    const {
      is_amc_lender,
      orderData,
    } = this.props
    if (orderData.job_type === 'amc' && is_amc_lender) {
      // only amc lender can view results
      return true
    } else if (orderData.job_type === 'review') {
      // review orders only exist for results, so show them
      return true
    } else {
      // otherwise show it based upon order review engine enablement
      return orderData?.review_engine?.enabled
    }
  }

  getTargetReview = () => {
    const { sub, submissionReviews } = this.props
    const reviews = submissionReviews?.results
    let targetReview
    for (const review in reviews) {
      if (reviews[review].submission_version === sub.version) {
        targetReview = reviews[review]
      }
    }
    return targetReview
  }

  hasReggoraResult = () => {
    const {
      sub,
    } = this.props
    if (this.reviewEngineEnabled()) {
      return this.getTargetReview()
    } else {
      return sub.review_result && sub.review_result.length > 0
    }
  }

  reviewEngineEnabled = () => {
    const { order, is_amc_lender } = this.props
    return (order?.review_engine?.enabled || is_amc_lender)
  }

  warrantyReviewEnabled = () => {
    return this.props.order?.review_engine?.repurchase_warranty_enabled
  }

  reviewRulesetsMatch = () => {
    const { orderSubmissionReviewRuleSetKey } = this.props
    const targetReview = this.getTargetReview()
    return orderSubmissionReviewRuleSetKey &&
     orderSubmissionReviewRuleSetKey === targetReview?.review_result?.[0].rule_set_key
  }

  showRuleSetChangeWarning = () => {
    const { showRulesetChangeWarnings } = this.state
    const { isMostRecentSubmission, sub } = this.props
    const isEditable = isMostRecentSubmission && !sub.lender_approved
    return isEditable && showRulesetChangeWarnings &&
      this.canViewReggoraReview() &&
      this.reviewEngineEnabled() &&
      checkPermission('reggora_review_action') &&
      !this.reviewRulesetsMatch()
  }

  getRuleSetChangeWarning = () => {
    const { orderSubmissionReviewRuleSetKey } = this.props
    const targetReview = this.getTargetReview()
    const targetRuleSet = targetReview?.review_result?.[0].rule_set_key
    const orderRuleSet = orderSubmissionReviewRuleSetKey
    return (
      <Badge className='badge-warning'><WarningOutlined />
        <span>Applicable Review Rule Set has changed.</span>
        {targetRuleSet && (<span>
          <strong> Previous ruleset:</strong> {targetRuleSet}
        </span>)}
        <strong> Current ruleset:</strong> {orderRuleSet}
      </Badge>
    )
  }

  isLenderMissingNumbers = (is_ead = false) => {
    const {
      orderData,
      loanData,
    } = this.props

    const numberSource = loanData.gse_settings || orderData.lender
    if (is_ead) {
      return !numberSource.ead_business_unit_number || !numberSource.fha_lender_id
    } else {
      let isMissingUCDPNumbers = false
      const {
        ucdp_business_unit_number,
        fnm_seller_number,
        fre_identification_number,
      } = numberSource
      // UCDP requires the UCDP number and one of the Fannie or Freddie numbers
      const missingBothGSENumbers = !fnm_seller_number && !fre_identification_number
      isMissingUCDPNumbers = !ucdp_business_unit_number || missingBothGSENumbers
      return isMissingUCDPNumbers
    }
  }

  hasAnyLenderNumber = () => {
    const {
      orderData,
      loanData,
    } = this.props
    const numberSource = loanData.gse_settings || orderData.lender
    const {
      ucdp_business_unit_number,
      fnm_seller_number,
      fre_identification_number,
      ead_business_unit_number,
      fha_lender_id,
    } = numberSource
    const anyUCDP = ucdp_business_unit_number || fnm_seller_number || fre_identification_number
    const anyEAD = ead_business_unit_number || fha_lender_id
    return anyUCDP || anyEAD
  }

  hasUcdpSubmission = () => {
    // most recent UCDP submission for this loan included this order and submission version number
    const {
      hasUcdpSubmission,
      isMostRecentUcdpSubmission,
    } = this.props
    return hasUcdpSubmission && isMostRecentUcdpSubmission
  }

  hasEadSubmission = () => {
    // most recent EAD submission for this loan included this order and submission version number
    const {
      hasEadSubmission,
      isMostRecentEadSubmission,
    } = this.props
    return hasEadSubmission && isMostRecentEadSubmission
  }

  hasUcdpResult = () => {
    const {
      ucdpSubmission,
    } = this.props
    return ucdpSubmission && ucdpSubmission.pathway_result
  }

  hasEadResult = () => {
    const {
      eadSubmission,
    } = this.props
    return eadSubmission && eadSubmission.pathway_result
  }

  hasGSEBlockErrorForUCDP = () => {
    const {
      sub,
    } = this.props
    return sub.gse_submission_for_ucdp_blocked_error
  }

  hasGSEBlockErrorForEAD = () => {
    const {
      sub,
    } = this.props
    return sub.gse_submission_for_ead_blocked_error
  }

  shouldRenderGSESubmitButtons = () => {
    // determines whether user can view GSE Submit Buttons, not necessarily whether they can submit
    const {
      is_amc_lender,
      orderData,
    } = this.props
    if (orderData.job_type === 'amc') {
      return is_amc_lender || this.hasAnyLenderNumber()
    } else if (orderData.job_type === 'va') {
      return false
    } else {
      const hasAnyLenderNumber = this.hasAnyLenderNumber()
      return hasAnyLenderNumber
    }
  }

  canViewGSEDetails = () => {
    // determines whether user can view GSE Details for this order, not whether those details exist
    const {
      orderData,
    } = this.props
    if (orderData.job_type === 'va') {
      return false
    } else {
      // by default we'll return true, because if there is data to see then the lender can see it
      return true
    }
  }

  canViewUCDPResults = () => {
    /**
    determine if user can view any UCDP info so that we know what to render

    dependent upon canViewGSEDetails with added logic to expose SSRs to lender client on Reggora AMC Orders, if those SSRs exist
    */
    const {
      orderData,
      is_amc_lender,
      ucdpSubmission,
    } = this.props
    const canViewGSEDetails = this.canViewGSEDetails()
    let showDownloads = false
    if (orderData.job_type === 'amc' && !is_amc_lender) {
      // lender client, if there is a pdf to download we can show it to them
      const hasUcdpSubmission = this.hasUcdpSubmission()
      const hasUcdpResult = this.hasUcdpResult()
      if (hasUcdpSubmission && hasUcdpResult) {
        const result = ucdpSubmission.pathway_result
        if (result.freddie_pdf || result.fannie_pdf || result.fha_pdf) {
          showDownloads = true
        }
      }
    }
    return canViewGSEDetails || showDownloads
  }

  canViewEADResults = () => {
    /**
    determine if user can view any EAD info so that we know what to render

    dependent upon canViewGSEDetails with added logic to expose SSRs to lender client on Reggora AMC Orders, if those SSRs exist
    */
    const {
      orderData,
      is_amc_lender,
      eadSubmission,
    } = this.props
    const canViewGSEDetails = this.canViewGSEDetails()
    let showDownloads = false
    if (orderData.job_type === 'amc' && !is_amc_lender) {
      // lender client, if there is a pdf to download we can show it to them
      const hasEadSubmission = this.hasEadSubmission()
      const hasEadResult = this.hasEadResult()
      if (hasEadSubmission && hasEadResult) {
        const result = eadSubmission.pathway_result
        if (result.freddie_pdf || result.fannie_pdf || result.fha_pdf) {
          showDownloads = true
        }
      }
    }
    return canViewGSEDetails || showDownloads
  }

  showReggoraReviewTab = () => {
    // tab only shows if we have data and user can view it
    const hasReggoraResult = this.hasReggoraResult()
    const canViewReggoraReview = this.canViewReggoraReview()
    const hasReviewPermission = checkPermission('reggora_review_view')
    return hasReggoraResult && canViewReggoraReview && hasReviewPermission
  }

  showUCDPTab = () => {
    // tab only shows if we have data and user can view it
    const hasUcdpSubmission = this.hasUcdpSubmission()
    const hasGSEBlockErrorForUCDP = this.hasGSEBlockErrorForUCDP()
    const canView = this.canViewUCDPResults()
    const hasData = hasUcdpSubmission || hasGSEBlockErrorForUCDP
    return canView && hasData
  }

  showEADTab = () => {
    // tab only shows if we have data and user can view it
    const hasEadSubmission = this.hasEadSubmission()
    const hasGSEBlockErrorForEAD = this.hasGSEBlockErrorForEAD()
    const hasData = hasEadSubmission || hasGSEBlockErrorForEAD
    const canView = this.canViewEADResults()
    return canView && hasData
  }

  showWarrantyReviewTab = () => {
    const hasReviewPermission = checkPermission('reggora_review_view')
    return this.warrantyReviewEnabled() && hasReviewPermission
  }

  // initialize some state
  initializeSelectedTab = () => {
    if (this.showWarrantyReviewTab()) {
      return tabName.RepurchaseWarranty
    }
    if (this.showReggoraReviewTab()) {
      return 'review'
    }
    if (this.showUCDPTab()) {
      return 'ucdp'
    }
    if (this.showEADTab()) {
      return 'ead'
    }

    // they can't see anything anyway
    return ''
  }

  state = {
    showingErrors: false,
    selectedTab: this.initializeSelectedTab(),
    selectedUcdpOverrides: [],
    selectedUcdpRevision: [],
    selectedEadOverrides: [],
    selectedEadRevision: [],
    selectedReggoraErrors: [],
    isUcdpSubmitting: false,
    isEadSubmitting: false,
    isApproveSubmissionModalOpen: false,
    submissionComment: '',
    showRulesetChangeWarnings: false,
    hasReviewArtifact: false,
    hasReviewCompReport: false,
  };

  initializeRevisions = () => {
    const {
      selectedUcdpRevision,
      selectedEadRevision,
      selectedReggoraErrors,
      selectedTab,
    } = this.state
    if (selectedTab === 'review') {
      return selectedReggoraErrors.map(() => {
        // TODO so they can request revisions on reggora errors too
        return {
          title: '',
          text: '',
        }
      })
    }
    if (selectedTab === 'ucdp') {
      return selectedUcdpRevision.map(reviewError => {
        return {
          title: '',
          text: reviewError.error,
        }
      })
    }
    if (selectedTab === 'ead') {
      return selectedEadRevision.map(reviewError => {
        return {
          title: '',
          text: reviewError.error,
        }
      })
    }

    if (selectedTab === tabName.RepurchaseWarranty) {
      return selectedReggoraErrors.map(() => ({
        title: '',
        text: '',
      }))
    }
  }

  submitToGSE = (isEAD, isResubmit) => {
    const {
      submitUCDP,
      submitEAD,
      orderData,
      sub,
      updateMinimumOrder,
    } = this.props
    this.setState({ isUcdpSubmitting: !isEAD, isEadSubmitting: isEAD })
    const submitTo = isEAD ? submitEAD : submitUCDP
    const order_id = orderData.id
    const version = sub.version
    submitTo.create({
      order_id,
      version,
      is_resubmit: isResubmit,
    }).then(res => {
      if (isEAD) {
        this.setState({ isEadSubmitting: false })
      } else {
        this.setState({ isUcdpSubmitting: false })
      }
      updateMinimumOrder({ loan_file: res.loan_file })
    })
  }

  submitToUCDP = () => {
    this.submitToGSE(false, false)
  }

  submitToEAD = () => {
    this.submitToGSE(true, false)
  }

  resubmitToUCDP = () => {
    this.submitToGSE(false, true)
  }

  resubmitToEAD = () => {
    this.submitToGSE(true, true)
  }

  getReviewTable = () => {
    const { sub, orderData } = this.props
    // rowSelection object indicates the need for row selection
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({ selectedReggoraErrors: selectedRows })
      },
      getCheckboxProps: record => ({
        disabled: record.name === 'Disabled User', // Column configuration not to be checked
        name: record.name,
      }),
    }

    const columns = [{
      title: 'Reggora Error(s) and Warning(s)',
      dataIndex: 'error',
    }, {
      title: 'Type',
      dataIndex: 'type',
      width: 150,
      filterMultiple: false,
      filters: [{ text: 'Warning', value: 'Warning' }, { text: 'Error', value: 'Error' }],
      onFilter: (value, record) => record.type === value,
      render: text => {
        if (text === 'Manually Overridable') {
          return <Badge className='badge-warning'>{text}</Badge>
        } else if (text === 'Auto Overridable') {
          return <Badge className='badge-info'>{text}</Badge>
        }
      },
    },
    ]

    const data = sub && sub.ucdp_preview &&
      sub.ucdp_preview.pathway_result ? sub.ucdp_preview.pathway_result.hard_stops.map(
        (hard_stop, index) => {
          return { key: index.toString(), error: hard_stop.description, type: hard_stop.overridable }
        }) : null

    if (sub && sub.ucdp_preview && sub.ucdp_preview.pathway_result && sub.ucdp_preview.pathway_result.status === 'success' && sub.ucdp_preview.pathway_result.error_messages.length === 0 && sub.ucdp_preview.pathway_result.hard_stops.length > 0) {
      return (<Table
        scroll={{ y: 300 }}
        size={'middle'}
        pagination={false}
        rowSelection={rowSelection}
        footer={() => <div className='w-100 d-flex flex-row'>
          <ModalTrigger
            component={BulkRevisionModal}
            modalClassName='modal-primary'
            revisions={this.initializeRevisions()}
            order_id={orderData.id}
          >
            <Button
              disabled={(this.state.selectedUcdpRevision.length + this.state.selectedUcdpOverrides.length) < 1}
              color='secondary'
              className='btn-secondary btn-sm'
            >
              Request Revision(s) for Selected Errors
            </Button>
          </ModalTrigger>
        </div>}
        columns={columns}
        dataSource={data}
      />)
    }
  }


  getReviewTab = () => {
    const { sub } = this.props
    // The review request is still processing
    if (sub && sub.ucdp_preview && sub.ucdp_preview.pathway_result && sub.ucdp_preview.pathway_result.status === 'processing') {
      return <Alert color="info" className='mb-0'><SyncOutlined className='mr-1' spin /><Rephrase> Reviewing appraisal for hardstops and errors </Rephrase><small className='ml-3'><b>(This is not a UCDP submission)</b></small></Alert>
    }

    if (sub && sub.ucdp_preview && sub.ucdp_preview.pathway_result && sub.ucdp_preview.pathway_result.status === 'success' && sub.ucdp_preview.pathway_result.error_messages.length === 0 && sub.ucdp_preview.pathway_result.hard_stops.length === 0) {
      return <Alert color="success" className='mb-0'><CheckCircleOutlined className='mr-1' /> Reggora has not identified any major hardstops after review</Alert>
    }

    if (sub && sub.ucdp_preview && sub.ucdp_preview.pathway_result && sub.ucdp_preview.pathway_result.status === 'error' && sub.ucdp_preview.pathway_result.error_messages.length !== 0) {
      return <Alert color="danger"><ExclamationCircleOutlined className='mr-1' /><Rephrase> Reggora could not review this appraisal: </Rephrase>{sub.ucdp_preview.pathway_result.error_messages.join('. ')}.</Alert>
    }

    if (sub && sub.ucdp_preview && sub.ucdp_preview.pathway_result && sub.ucdp_preview.pathway_result.status === 'success' && sub.ucdp_preview.pathway_result.error_messages.length === 0 && sub.ucdp_preview.pathway_result.hard_stops.length > 0) {
      return this.getReviewTable()
    }
  }

  getReggoraScore = () => {
    const { sub } = this.props

    const {
      review_result,
    } = sub
    const scoreDecimal = review_result[0].percentage_passed
    const score = Math.round(scoreDecimal * 100)
    return (<p>
      <span><b>{score}</b></span>
      <span className="text-muted">%</span>
    </p>)
  }


  getScoreBadgeContent = (score) => {
    let content = null

    if (score > 2.5 && score <= 5) {
      content = <p><span className="text-danger"><b>{score}</b></span> <span className="text-muted">out of 5</span></p>
    } else if (score >= 0 && score <= 2.5) {
      content = <p><span className="text-success"><b>{score}</b></span> <span className="text-muted">out of 5</span></p>
    } else {
      content = <p><span className="text-muted">Not Available</span></p>
    }
    return content
  }

  getScoreBadge = (badgeType) => {
    const { ucdpSubmission } = this.props
    const label = badgeType === 'cu' ? 'CU Score:' : 'LCA Score:'
    let content = <p><span className="text-muted">Not Available</span></p>

    if (badgeType === 'cu') {
      // Get CU Score Content
      if (
        ucdpSubmission &&
        ucdpSubmission.pathway_result &&
        ucdpSubmission.pathway_result.underwriter_score
      ) {
        const cuScore = parseFloat(ucdpSubmission.pathway_result.underwriter_score)
        content = this.getScoreBadgeContent(cuScore)
      }
    } else {
      // Get LCA Score Content
      if (
        ucdpSubmission &&
        ucdpSubmission.pathway_result &&
        ucdpSubmission.pathway_result.lca_score
      ) {
        const lcaScore = parseFloat(ucdpSubmission.pathway_result.lca_score)
        content = this.getScoreBadgeContent(lcaScore)
      }
    }

    return (
      <div className='spacer'>
        <p><small>{label}</small></p>
        <div>{content}</div>
      </div>
    )
  }

  getCuScoreBadge = () => {
    return this.getScoreBadge('cu')
  }

  getLcaScoreBadge = () => {
    return this.getScoreBadge('lca')
  }

  makeFileName = (name) => {
    const {
      loanData,
      sub,
    } = this.props
    return `${loanData.number} V${sub.version} - ${name}.pdf`
  }

  onUcdpRowSelection = (selectedUcdpOverrides, selectedUcdpRevision) => {
    this.setState({ selectedUcdpOverrides, selectedUcdpRevision })
  }

  onEadRowSelection = (selectedEadOverrides, selectedEadRevision) => {
    this.setState({ selectedEadOverrides, selectedEadRevision })
  }

  getFailBadge = (ruleId) => {
    return (
      <Popover
        content={ruleId}
        title="Rule ID"
        style={{ width: 600 }}
      >
        <span><Tag color='red'>Failed</Tag></span>
      </Popover>
    )
  }

  getPassBadge = (ruleId) => {
    return (
      <Popover
        content={ruleId}
        title="Rule ID"
        style={{ width: 600 }}
      >
        <span><Tag color='green'>Passed</Tag></span>
      </Popover>
    )
  }

  getReggoraTab = () => {
    const {
      sub,
    } = this.props
    if (!sub.review_result || sub.review_result.length === 0) {
      return <Alert color='light' className='mb-0'><ExclamationCircleOutlined className='mr-1' /> This submission has not yet undergone Reggora Review</Alert>
    } else {
      const {
        review_result,
      } = sub

      const columns = [
        {
          title: 'Status',
          dataIndex: 'status',
          width: 150,
        }, {
          title: <span>Reggora Findings</span>,
          dataIndex: 'description',
        },
      ]

      const failed_data = []
      const passed_data = []
      const exportable_data = []
      review_result.forEach(result => {
        if (result.failed_rules) {
          result.failed_rules.forEach(failure => {
            if (!failure.is_actual_error) {
              const ruleId = failure.rule.id
              failure.errors.forEach(err => {
                failed_data.push({
                  status: this.getFailBadge(ruleId),
                  code: err.status,
                  description: err.error,
                })
              })
            }
          })
        }
        if (result.passed_rules) {
          result.passed_rules.forEach(rule => {
            const ruleId = rule.id
            passed_data.push({
              status: this.getPassBadge(ruleId),
              passed: true,
              description: rule.rule_name,
            })
          })
        }

        if (result.data) {
          const { comparables, ...resultExport } = result.data
          if (comparables) {
            comparables.forEach((comparable, idx) => {
              const compNumber = idx + 1
              Object.keys(comparable).forEach(key => {
                resultExport[`comparable_${compNumber}_${key}`] = comparable[key]
              })
            })
          }

          let key
          const keys = Object.keys(resultExport)
          let n = keys.length
          const newObj = {}
          while (n--) {
            key = keys[n]
            newObj[apiToReadable(key)] = resultExport[key]
          }
          exportable_data.push(newObj)
        }
      })

      const data = failed_data.concat(passed_data)

      return (
        <div className='ucdp-results-table'>
          <div className='ucdp-summary justify-content-between'>
            <div className='d-flex'>
              {this.reviewEngineEnabled() &&
                <div className='mr-3'>
                  <p><small>Score:</small></p>
                  <p>{this.getReggoraScore()}</p>
                </div>
              }
              <div>
                <p><small>Date Reviewed:</small></p>
                <p>{utcToLocal(review_result[0].created).format('lll')}</p>
              </div>

            </div>
          </div>
          <Table
            scroll={{ y: 300 }}
            size={'middle'}
            pagination={false}
            // rowSelection={rowSelection}   // commenting out for now b/c the checkboxes don't serve a purpose
            footer={() => (
              <div className='w-100 d-flex justify-content-between'>
                <div>
                  {/* <ModalTrigger
                    component={BulkRevisionModal}
                    modalClassName='modal-primary'
                    revisions={this.initializeRevisions()}
                    order_id={orderData.id}
                  >
                    <Button
                    disabled={(this.state.selectedUcdpRevision.length + this.state.selectedUcdpOverrides.length) < 1}
                    color='secondary' className='btn-secondary btn-sm mr-3'>Request Revision(s) for Selected Findings</Button>
                  </ModalTrigger> */}
                </div>
              </div>
            )}
            columns={columns}
            dataSource={data}
          />
        </div>
      )
    }
  }

  getSelectedTab = (isEditable) => {
    const {
      selectedTab,
      selectedUcdpRevision,
      selectedUcdpOverrides,
      selectedEadRevision,
      selectedEadOverrides,
    } = this.state
    const {
      ucdpSubmission,
      ucdpAppraisalIndex,
      orderData,
      sub,
      hasUcdpSubmission,
      eadSubmission,
      eadAppraisalIndex,
      hasEadSubmission,
      refreshOrder,
    } = this.props

    if (selectedTab === tabName.RepurchaseWarranty) {
      if (this.showWarrantyReviewTab()) {
        return <RepurchaseWarranty
          submission={sub}
          isEditable={isEditable}
          orderData={orderData}
          refetchOrder={refreshOrder}
          submissionVersion={sub.version}
        />
      }
    }

    if (selectedTab === tabName.Review) {
      if (this.reviewEngineEnabled()) {
        return <ReggoraReview
          submission={sub}
          isEditable={isEditable}
          orderData={orderData}
          refetchOrder={refreshOrder}
          submissionVersion={sub.version}
        />
      }
      return this.getReggoraTab()
    }

    if (selectedTab === 'ucdp') {
      return <SubmissionTableUcdp
        downloadDocument={this.downloadDocument}
        getCuScoreBadge={this.getCuScoreBadge}
        getLcaScoreBadge={this.getLcaScoreBadge}
        initializeRevisions={this.initializeRevisions}
        onUcdpRowSelection={this.onUcdpRowSelection}
        canViewUCDPResults={this.canViewUCDPResults}
        canViewGSEDetails={this.canViewGSEDetails}
        selectedUcdpRevision={selectedUcdpRevision}
        selectedUcdpOverrides={selectedUcdpOverrides}
        ucdpSubmission={ucdpSubmission}
        ucdpAppraisalIndex={ucdpAppraisalIndex}
        orderData={orderData}
        sub={sub}
        hasUcdpSubmission={hasUcdpSubmission}
        refreshOrder={refreshOrder}
        flags={this.props.flags}
      />
    }

    if (selectedTab === 'ead') {
      return <SubmissionTableEad
        downloadDocument={this.downloadDocument}
        initializeRevisions={this.initializeRevisions}
        onEadRowSelection={this.onEadRowSelection}
        canViewEADResults={this.canViewEADResults}
        canViewGSEDetails={this.canViewGSEDetails}
        selectedEadRevision={selectedEadRevision}
        selectedEadOverrides={selectedEadOverrides}
        eadSubmission={eadSubmission}
        eadAppraisalIndex={eadAppraisalIndex}
        orderData={orderData}
        sub={sub}
        hasEadSubmission={hasEadSubmission}
        refreshOrder={refreshOrder}
        flags={this.props.flags}
      />
    }

    return null
  }

  getUcdpEadInfo = () => {
    const {
      loanData,
      hasUcdpSubmission,
      isMostRecentUcdpSubmission,
      ucdpSubmission,
      hasEadSubmission,
      isMostRecentEadSubmission,
      eadSubmission,
      sub,
      submitUCDP,
      submitEAD,
    } = this.props

    const shouldRenderGSESubmitButtons = this.shouldRenderGSESubmitButtons()
    let ucdpOnClick = this.submitToUCDP
    let eadOnClick = this.submitToEAD
    let ucdpDisabled = false
    let eadDisabled = false
    let ucdpText = ''
    let eadText = ''
    let eadPopoverText = ''
    let ucdpPopoverText = ''
    if (!shouldRenderGSESubmitButtons) {
      return {
        shouldRenderGSESubmitButtons,
        eadDisabled,
        eadText,
        eadPopoverText,
        eadOnClick,
        ucdpDisabled,
        ucdpText,
        ucdpPopoverText,
        ucdpOnClick,
      }
    } else {
      if (!sub.xml_report || !sub.xml_report.document_url) {
        // this submission lacks an XML, so it cannot be submitted
        ucdpDisabled = true
        eadDisabled = true
        ucdpText = 'Missing XML for UCDP Submit'
        eadText = 'Missing XML for EAD Submit'
      } else {
        // verify lender has required business numbers
        const isLenderMissingNumbersUCDP = this.isLenderMissingNumbers()
        const isLenderMissingNumbersEAD = this.isLenderMissingNumbers(true)
        const useBranchCreds = loanData?.gse_settings?.gse_settings_source_branch
        if (isLenderMissingNumbersEAD) {
          eadDisabled = true
          eadText = 'Cannot Submit to EAD'
          const popoverTextStart = useBranchCreds ? 'The branch associated with this order' : 'This lender'
          eadPopoverText = `${popoverTextStart} is missing one of the numbers required for submission. Please ensure that your EAD business number and FHA lender number are all entered in settings.`
        }
        if (isLenderMissingNumbersUCDP) {
          ucdpDisabled = true
          ucdpText = 'Cannot Submit to UCDP'
          const popoverTextStart = useBranchCreds ? 'The branch associated with this order' : 'This lender'
          ucdpPopoverText = `${popoverTextStart} is missing one of the numbers required for submission. Please ensure that your UCDP business number and Fannie Mae or Freddie Mac numbers are all entered in settings.`
        }

        if (loanData.ucdp_submissions.some(submission => submission.external_result)) {
          ucdpDisabled = eadDisabled = true
          eadText = 'Cannot Submit to EAD'
          ucdpText = 'Cannot Submit to UCDP'
          eadPopoverText = ucdpPopoverText = 'This submission is being handled by an external integration and cannot be submitted via Reggora.'
        }

        // check for loading, means they are currently submitting one
        if (submitUCDP.loading) {
          ucdpDisabled = true
        }

        if (submitEAD.loading) {
          eadDisabled = true
        }

        // if we already set text then something is wrong, but if not then we show the regular submit text
        if (!ucdpText) {
          // check for prior submissions
          if (hasUcdpSubmission) {
            if (isMostRecentUcdpSubmission) {
              if (ucdpSubmission && ucdpSubmission.pathway_result && ucdpSubmission.pathway_result.status === 'error') {
                ucdpText = 'Resubmit to UCDP'
                ucdpOnClick = this.resubmitToUCDP
              } else {
                ucdpText = 'Already Submitted to UCDP'
                ucdpDisabled = true
              }
            } else {
              ucdpText = 'Resubmit to UCDP'
            }
          } else {
            ucdpText = 'Submit to UCDP'
          }
        }

        if (!eadText) {
          // check for prior submissions
          if (hasEadSubmission) {
            if (isMostRecentEadSubmission) {
              if (eadSubmission && eadSubmission.pathway_result && eadSubmission.pathway_result.status === 'error') {
                eadText = 'Resubmit to EAD'
                eadOnClick = this.resubmitToEAD
              } else {
                eadText = 'Already Submitted to EAD'
                eadDisabled = true
              }
            } else {
              eadText = 'Resubmit to EAD'
            }
          } else if (!loanData.case_number) {
            eadText = 'Missing Case Number to Submit to EAD'
            eadDisabled = true
            eadPopoverText = 'Please edit the loan by adding an FHA case number in order to submit to EAD'
          } else {
            eadText = 'Submit to EAD'
          }
        }
      }

      return {
        shouldRenderGSESubmitButtons,
        eadDisabled,
        eadText,
        eadPopoverText,
        eadOnClick,
        ucdpDisabled,
        ucdpText,
        ucdpPopoverText,
        ucdpOnClick,
      }
    }
  }

  getSubmit = () => {
    const {
      loanData,
      hasUcdpSubmission,
      isMostRecentUcdpSubmission,
      ucdpSubmission,
      hasEadSubmission,
      isMostRecentEadSubmission,
      eadSubmission,
      sub,
      submitUCDP,
      submitEAD,
    } = this.props
    const {
      isUcdpSubmitting,
      isEadSubmitting,
    } = this.state
    const shouldRenderGSESubmitButtons = this.shouldRenderGSESubmitButtons()
    if (!shouldRenderGSESubmitButtons) {
      return null
    } else {
      let ucdpOnClick = this.submitToUCDP
      let eadOnClick = this.submitToEAD
      let ucdpDisabled = false
      let eadDisabled = false
      let ucdpIcon = 'inbox'
      let eadIcon = 'inbox'
      let ucdpText = ''
      let eadText = ''
      let ucdpPopoverText = ''
      let eadPopoverText = ''

      if (!sub.xml_report || !sub.xml_report.document_url) {
        // this submission lacks an XML, so it cannot be submitted
        ucdpDisabled = true
        eadDisabled = true
        ucdpText = 'Missing XML for UCDP Submit'
        eadText = 'Missing XML for EAD Submit'
      } else {
        // verify lender has required business numbers
        const isLenderMissingNumbersUCDP = this.isLenderMissingNumbers()
        const isLenderMissingNumbersEAD = this.isLenderMissingNumbers(true)
        if (isLenderMissingNumbersEAD) {
          eadDisabled = true
          eadText = 'Cannot Submit to EAD'
          eadPopoverText = 'This lender is missing one of the numbers required for submission. Please ensure that your EAD business number and FHA lender number are all entered in settings.'
        }
        if (isLenderMissingNumbersUCDP) {
          ucdpDisabled = true
          ucdpText = 'Cannot Submit to UCDP'
          ucdpPopoverText = 'This lender is missing one of the numbers required for submission. Please ensure that your UCDP business number, Fannie Mae number, and Freddie Mac number are all entered in settings.'
        }

        if (loanData.ucdp_submissions.some(submission => submission.external_result)) {
          ucdpDisabled = eadDisabled = true
          eadText = 'Cannot Submit to EAD'
          ucdpText = 'Cannot Submit to UCDP'
          eadPopoverText = ucdpPopoverText = 'This submission is being handled by an external integration and cannot be submitted via Reggora.'
        }

        // check for loading, means they are currently submitting one
        if (submitUCDP.loading) {
          ucdpDisabled = true
          // only show loading on the one being submitted
          if (isUcdpSubmitting) {
            ucdpIcon = 'loading'
          }
        }

        if (submitEAD.loading) {
          eadDisabled = true
          // only show loading on the one being submitted
          if (isEadSubmitting) {
            eadIcon = 'loading'
          }
        }

        // if we already set text then something is wrong, but if not then we show the regular submit text
        if (!ucdpText) {
          // check for prior submissions
          if (hasUcdpSubmission) {
            if (isMostRecentUcdpSubmission) {
              if (ucdpSubmission && ucdpSubmission.pathway_result && ucdpSubmission.pathway_result.status === 'error') {
                ucdpText = 'Resubmit to UCDP'
                ucdpOnClick = this.resubmitToUCDP
              } else {
                ucdpText = 'Already Submitted to UCDP'
                ucdpDisabled = true
              }
            } else {
              ucdpText = 'Resubmit to UCDP'
            }
          } else {
            ucdpText = 'Submit to UCDP'
          }
        }

        if (!eadText) {
          // check for prior submissions
          if (hasEadSubmission) {
            if (isMostRecentEadSubmission) {
              if (eadSubmission && eadSubmission.pathway_result && eadSubmission.pathway_result.status === 'error') {
                eadText = 'Resubmit to EAD'
                eadOnClick = this.resubmitToEAD
              } else {
                eadText = 'Already Submitted to EAD'
                eadDisabled = true
              }
            } else {
              eadText = 'Resubmit to EAD'
            }
          } else if (!loanData.case_number) {
            eadText = 'Missing Case Number to Submit to EAD'
            eadDisabled = true
            eadPopoverText = 'Please edit the loan by adding an FHA case number in order to submit to EAD'
          } else {
            eadText = 'Submit to EAD'
          }
        }
      }

      let ucdpButton = (<Button onClick={ucdpOnClick} disabled={ucdpDisabled} color='primary' className='btn-primary btn-sm'><LegacyIcon className='mr-1' type={ucdpIcon} /> {ucdpText}</Button>)
      if (ucdpPopoverText) {
        // disabled elements don't trigger hover events, so we wrap the button in another element
        ucdpButton = (
          <Tooltip title={ucdpPopoverText}>
            <div className='p-1'>
              {ucdpButton}
            </div>
          </Tooltip>
        )
      }

      let eadButton = (<Button onClick={eadOnClick} disabled={eadDisabled} color='primary' className='btn-primary btn-sm'><LegacyIcon className='mr-1' type={eadIcon} /> {eadText}</Button>)
      if (eadPopoverText) {
        // disabled elements don't trigger hover events, so we wrap the button in another element
        eadButton = (
          <Tooltip title={eadPopoverText}>
            <div className='p-1'>
              {eadButton}
            </div>
          </Tooltip>
        )
      }

      return (
        <div className='d-flex'>
          {ucdpButton}
          {eadButton}
        </div>
      )
    }
  }

  getUcdpIcon = (canShowUcdpData, canShowUcdpError) => {
    const { ucdpSubmission } = this.props
    const dangerIcon = <ExclamationCircleOutlined className='text-danger mr-1' />
    let ucdpIcon = null

    if (canShowUcdpData) {
      const neutralIcon = <MinusCircleOutlined className='text-muted mr-1' />
      const successIcon = <CheckCircleOutlined className='text-success mr-1' />

      const pathway_result = ucdpSubmission.pathway_result
      // first just check the status of the result
      const pathway_status = pathway_result.status
      if (pathway_status === 'error') {
        ucdpIcon = dangerIcon
      } else if (pathway_status === 'processing') {
        ucdpIcon = neutralIcon
      } else if (pathway_status === 'success') {
        // Get the skinny on the status of our Fannie Submission
        let submittedToFannie = false
        let fannieSubmissionSuccessful = false

        if (pathway_result.fannie_result && pathway_result.fannie_result.condition) {
          submittedToFannie = true
          if (pathway_result.fannie_result.condition === 'SUCCESSFUL') {
            fannieSubmissionSuccessful = true
          }
        }

        // Get the skinny on the status of our Freddie Submission
        let submittedToFreddie = false
        let freddieSubmissionSuccessful = false

        if (pathway_result.freddie_result && pathway_result.freddie_result.condition) {
          submittedToFreddie = true
          if (pathway_result.freddie_result.condition === 'SUCCESSFUL') {
            freddieSubmissionSuccessful = true
          }
        }

        // check for fha submission
        let submittedToFHA = false
        let fhaSubmissionSuccessful = false
        if (pathway_result.fha_result && pathway_result.fha_result.condition) {
          submittedToFHA = true
          if (pathway_result.fha_result.condition === 'SUCCESSFUL') {
            fhaSubmissionSuccessful = true
          }
        }

        if (submittedToFannie || submittedToFreddie) {
          if (!fannieSubmissionSuccessful || !freddieSubmissionSuccessful) {
            ucdpIcon = dangerIcon
          } else {
            ucdpIcon = successIcon
          }
        } else if (submittedToFHA) {
          if (!fhaSubmissionSuccessful) {
            ucdpIcon = dangerIcon
          } else {
            ucdpIcon = successIcon
          }
        } else {
          // we have no results
          ucdpIcon = neutralIcon
        }
      }
    } else if (canShowUcdpError) {
      // there is no actual data to show them, but there is a submission blocked error
      // so automatic GSE submission failed, and no one manually submitted afterwards
      ucdpIcon = dangerIcon
    }

    return ucdpIcon
  }

  getEadIcon = (canShowEadData, canShowEadError) => {
    const { eadSubmission } = this.props
    const dangerIcon = <ExclamationCircleOutlined className='text-danger mr-1' />
    let eadIcon = null

    if (canShowEadData) {
      const neutralIcon = <MinusCircleOutlined className='text-muted mr-1' />
      const successIcon = <CheckCircleOutlined className='text-success mr-1' />

      const pathway_result = eadSubmission.pathway_result
      // first just check the status of the result
      const pathway_status = pathway_result.status
      if (pathway_status === 'error') {
        eadIcon = dangerIcon
      } else if (pathway_status === 'processing') {
        eadIcon = neutralIcon
      } else if (pathway_status === 'success') {
        // Get the skinny on the status of our Fannie Submission
        let submittedToFannie = false
        let fannieSubmissionSuccessful = false

        if (pathway_result.fannie_result && pathway_result.fannie_result.condition) {
          submittedToFannie = true
          if (pathway_result.fannie_result.condition === 'SUCCESSFUL') {
            fannieSubmissionSuccessful = true
          }
        }

        // Get the skinny on the status of our Freddie Submission
        let submittedToFreddie = false
        let freddieSubmissionSuccessful = false

        if (pathway_result.freddie_result && pathway_result.freddie_result.condition) {
          submittedToFreddie = true
          if (pathway_result.freddie_result.condition === 'SUCCESSFUL') {
            freddieSubmissionSuccessful = true
          }
        }

        // check for fha submission
        let submittedToFHA = false
        let fhaSubmissionSuccessful = false
        if (pathway_result.fha_result && pathway_result.fha_result.condition) {
          submittedToFHA = true
          if (pathway_result.fha_result.condition === 'SUCCESSFUL') {
            fhaSubmissionSuccessful = true
          }
        }

        if (submittedToFannie || submittedToFreddie) {
          if (!fannieSubmissionSuccessful || !freddieSubmissionSuccessful) {
            eadIcon = dangerIcon
          } else {
            eadIcon = successIcon
          }
        } else if (submittedToFHA) {
          if (!fhaSubmissionSuccessful) {
            eadIcon = dangerIcon
          } else {
            eadIcon = successIcon
          }
        } else {
          // we have no results
          eadIcon = neutralIcon
        }
      }
    } else if (canShowEadError) {
      // there is no actual data to show them, but there is a submission blocked error
      // so automatic GSE submission failed, and no one manually submitted afterwards
      eadIcon = dangerIcon
    }

    return eadIcon
  }

  getExclusionaryListAlertIcon = () => {
    return <Alert color="danger" className=''>This submission has matched an entry on your exclusionary list.</Alert>
  }

  handleApproveSubmissionModalClose = () => {
    this.setState({ isApproveSubmissionModalOpen: false })
  }

  showApproveSubmissionModal = () => {
    this.setState({ isApproveSubmissionModalOpen: true })
  }

  handleApproveSubmissionOnSubmit = () => {
    const { orderData, sub, addVendorScore, is_amc_lender, currentUserId } = this.props
    this.props.approveReport.put({
      id: orderData.id,
      version: sub.version,
      user_id: currentUserId,
      approval_comment: this.state.submissionComment,
    }).then(_ => {
      this.handleApproveSubmissionModalClose()
      this.props.refreshOrder()
      if (is_amc_lender && orderData.job_type === 'amc') {
        addVendorScore({ orderId: orderData.id, panelistId: orderData.accepted?.id })
      }
    })
  }

  handleUnapproveSubmissionModalClose = () => {
    this.setState({ isUnapproveSubmissionModalOpen: false })
  }

  showUnapproveSubmissionModal = () => {
    this.setState({ isUnapproveSubmissionModalOpen: true })
  }

  handleUnapproveSubmissionOnSubmit = async () => {
    const { sub } = this.props
    await this.props.unapproveReport.put({
      submission_id: sub.id,
    })

    this.handleUnapproveSubmissionModalClose()
    this.props.refreshOrder()
  }

  handleCommentInputChange = (e) => {
    this.setState({ submissionComment: e.target.value })
  }


  render() {
    const {
      auto_send_borrower_appraisal_delay,
      auto_send_borrower_appraisal,
      canSeeSubmissionTab,
      currentUserId,
      is_amc_lender,
      is_review_appraiser,
      isMostRecentSubmission,
      loanData,
      onHide,
      order,
      orderData,
      refreshOrder,
      review_appraisal_hours_end,
      review_appraisal_hours_start,
      send_borrower_appraisal,
      sub,
    } = this.props
    const { isApproveSubmissionModalOpen, isUnapproveSubmissionModalOpen } = this.state
    const hasUcdpSubmission = this.hasUcdpSubmission()
    const hasEadSubmission = this.hasEadSubmission()
    const hasGSEBlockErrorForUCDP = this.hasGSEBlockErrorForUCDP()
    const hasGSEBlockErrorForEAD = this.hasGSEBlockErrorForEAD()

    const showReggoraReview = this.showReggoraReviewTab()
    const showUcdpTab = this.showUCDPTab()
    const showEadTab = this.showEADTab()

    const showReviewTabs = showReggoraReview || showUcdpTab || showEadTab
    const canShowConsumerDelivery = orderData.job_type !== 'review'
    const all_statuses = []

    if (orderData && orderData.available_statuses && orderData.available_statuses.length > 0) {
      orderData.available_statuses.forEach(status => all_statuses.push(status.statusKey))
    }

    const is_reggora_amc_order_in_new_approval_workflow = all_statuses.includes('amp_review')

    let approved = ''
    if (sub.lender_approved) {
      approved = 'APPROVED'
    }

    // If we are in the AMC lender then we should treat the order as approved if we have the
    // new is_reggora_amc_approved bool set.
    if (sub.is_reggora_amc_approved && is_reggora_amc_order_in_new_approval_workflow && is_amc_lender) {
      approved = 'APPROVED'
    }

    let isEditable = false
    // If we are in the new workflow we want to turn off the "editable", which really controls whether
    // or not a submission can be apprvoed again.
    if (is_reggora_amc_order_in_new_approval_workflow) {
      if (is_amc_lender && !sub.is_reggora_amc_approved) {
        isEditable = true
      } else if (!is_amc_lender && !sub.lender_approved) {
        isEditable = true
      }
    } else {
      isEditable = !sub.lender_approved
    }

    if (isEditable) {
      // Extra logic check to turn off approval on old submissions
      if (orderData?.submissions && orderData.submissions.length > 0) {
        const latestVersion = orderData.submissions[orderData.submissions.length - 1].version
        if (sub.version !== latestVersion) {
          isEditable = false
        }
      }
    }

    const is_reggora_amc_order = orderData && orderData.job_type === 'amc'

    let auto_send_to_borrower_status = ''
    let auto_send_to_borrower_status_color = 'text-muted'
    let product_auto_sent = false
    // check for any products on the order that have auto borower delivery on
    if (order && order.products) {
      order.products.forEach(p => {
        if (p.auto_sent_to_borrower) {
          product_auto_sent = true
        }
      })
    }
    // check if the lender has auto send appraisal to borrower permissions
    if (canShowConsumerDelivery && send_borrower_appraisal && auto_send_borrower_appraisal && product_auto_sent) {
      // check if the auto send for this submission has been blocked
      if (sub.auto_send_to_borrower_blocked) {
        auto_send_to_borrower_status = 'Appraisal automatic sending stopped'
        auto_send_to_borrower_status_color = 'text-danger'
      } else {
        // check if the appraisal has already been sent to the borrower
        if (sub.auto_sent_to_borrower) {
          const auto_sent_to_borrower_time = moment(utcToLocal(sub.auto_sent_to_borrower_time)).format('h:mm a, MMM Do YYYY')
          auto_send_to_borrower_status = `Automatically sent appraisal to borrower at ${auto_sent_to_borrower_time}`
          auto_send_to_borrower_status_color = 'text-success'
        } else {
          // check if the submission requires approval
          let appraisal_delay = auto_send_borrower_appraisal_delay
          if (this.props.lenderData.low_value_auto_send_borrower_appraisal && checkLowValuation(sub, loanData)) {
            appraisal_delay = this.props.lenderData.low_value_auto_send_borrower_appraisal_delay
          }
          if (orderData.job_type === 'amc' || all_statuses.includes('under_review')) {
            if (sub.lender_approved) {
              const adjusted_auto_send_borrower_appraisal_delay = calculate_adjusted_delay(
                appraisal_delay,
                sub.lender_approved_time,
                review_appraisal_hours_start,
                review_appraisal_hours_end
              )
              const time = moment(utcToLocal(sub.lender_approved_time)).add(adjusted_auto_send_borrower_appraisal_delay, 'hours').format('h:mm a, MMM Do YYYY')
              auto_send_to_borrower_status = `Automatically sending appraisal to borrower at ${time}`
              auto_send_to_borrower_status_color = 'text-muted'
            } else {
              // waiting for approval. don't show the auto send status icon
              // can do something here later if we want
            }
          } else {
            const adjusted_auto_send_borrower_appraisal_delay = calculate_adjusted_delay(
              appraisal_delay,
              sub.created,
              review_appraisal_hours_start,
              review_appraisal_hours_end
            )
            const time = moment(utcToLocal(sub.created)).add(adjusted_auto_send_borrower_appraisal_delay, 'hours').format('h:mm a, MMM Do YYYY')
            auto_send_to_borrower_status = `Automatically sending appraisal to borrower at ${time}`
            auto_send_to_borrower_status_color = 'text-muted'
          }
        }
      }
    }

    const hasViewSubmissionPermission = checkPermission('submission_download')
    const hasViewExternalReviewPermission = checkPermission('submission_view_external')

    const shouldShowExternalReviewDocumentUI = (
      hasViewSubmissionPermission && hasViewExternalReviewPermission
    )

    const shouldShowReviewArtifactDownload = (
      this.reviewEngineEnabled() && this.state.hasReviewArtifact && sub.lender_approved
    )
    const shouldShowReviewCompReportDownload = (
      this.reviewEngineEnabled() && this.state.hasReviewCompReport
    )

    return (
      <div className="card mb-3">
        <div className="card-body">

          <div className="w-100 d-flex justify-content-between">
            <h5 className="card-title">Submission {sub.version} {approved} </h5>
          </div>
          <div className="w-100 d-flex  justify-content-between">
            <div>
              <h6 className="card-subtitle text-muted">{utcToLocal(sub.created).format('lll')}</h6>
              { this.showRuleSetChangeWarning()
                ? <div>
                  {this.getRuleSetChangeWarning()}
                </div> : null }
            </div>
            <div className="submission-actions-container">
              {(shouldShowReviewCompReportDownload) &&
                  <Fragment>
                    <AntdButton onClick={this.downloadReviewCompReport}>
                      <DownloadOutlined className='mr-1' /> Comparables Report
                    </AntdButton>
                  </Fragment>
              }
              {
                sub.lender_approved &&
                sub.id === _.last(orderData?.submissions)?.id &&
                checkPermission('submission_unapprove') &&
                // we will not allow submissions to be unapproved from the lender back to the AMC team
                !is_amc_lender &&
                !(is_reggora_amc_order && !is_reggora_amc_order_in_new_approval_workflow) &&
                <Fragment>
                  <AntdButton onClick={this.showUnapproveSubmissionModal}>
                    Unapprove Submission
                  </AntdButton>
                  <Modal
                    visible={isUnapproveSubmissionModalOpen}
                    onCancel={this.handleUnapproveSubmissionModalClose}
                    closable={false}
                    footer={[
                      <AntdButton key="back" onClick={this.handleUnapproveSubmissionModalClose}>
                        Cancel
                      </AntdButton>,
                      <AntdButton
                        key="submit"
                        type="primary"
                        loading={this.props.unapproveReport.loading}
                        onClick={this.handleUnapproveSubmissionOnSubmit}
                      >
                        Unapprove
                      </AntdButton>,
                    ]}
                  >
                    <ApproveSubmissionModal
                      title={'Unapprove Report'}
                      description={
                        <>
                          <p>Are you sure you want to unapprove this report?</p>
                          <p>
                            <b>NOTE</b>: To prevent automatically sending this appraisal to the borrower,
                            you must click &quot;Stop Auto Send&quot; under Actions.
                          </p>
                        </>
                      }
                    />
                  </Modal>
                </Fragment>
              }
              {((
                is_amc_lender &&
                  !sub.lender_approved &&
                  !is_review_appraiser &&
                  !is_reggora_amc_order_in_new_approval_workflow) ||
                  (isEditable && checkPermission('submission_approve'))) &&
                  <Fragment>
                    <AntdButton
                      onClick={this.showApproveSubmissionModal}
                      style={{ background: '#005DB4', borderColor: 'white' }}
                      type="primary"
                    >
                      Approve Submission
                    </AntdButton>
                    <Modal
                      style={{ }}
                      className={this.hasReggoraResult() ? 'comment-modal with-comment' : 'comment-modal'}
                      visible={isApproveSubmissionModalOpen}
                      onOk={{}}
                      onCancel={this.handleApproveSubmissionModalClose}
                      closable={false}
                      footer={[
                        <AntdButton key="back" onClick={this.handleApproveSubmissionModalClose}>
                          Cancel
                        </AntdButton>,
                        <AntdButton
                          key="submit"
                          type="primary"
                          loading={this.props.approveReport.loading}
                          onClick={this.handleApproveSubmissionOnSubmit}
                        >
                          Approve
                        </AntdButton>,
                      ]}
                    >
                      <ApproveSubmissionModal
                        title={'Approve Report'}
                        description={'Are you sure you want to approve this report?'}
                      />
                      {this.hasReggoraResult() ? <AddComment
                        comment={this.state.submissionComment}
                        onChange={this.handleCommentInputChange}
                        minRows={25}
                        maxRows={100}
                      /> : null}
                    </Modal>
                  </Fragment>
              }
              {
                (checkPermission('revisions_create_edit')) && this.props.flags?.plat1860EnableMultiStepRevision && isMostRecentSubmission && (
                  <AntdModalTrigger
                    modal={{
                      component: RevisionModal,
                      maskClosable: false,
                      size: 'large',
                    }}
                    order_id={orderData.id}
                    refetchOrder={refreshOrder}
                    is_amc_lender={is_amc_lender}
                    job_type={orderData.job_type}
                    submissionVersion={sub.version}
                  >
                    <AntdButton>Request Revision</AntdButton>
                  </AntdModalTrigger>
                )
              }
              {this.props.flags?.reviewEscalationsDemo && <AntdModalTrigger
                modal={{
                  component: ReviewEscalationModal,
                  maskClosable: false,
                  size: 'small',
                }}
                orderId={orderData.id}
                refreshOrder={refreshOrder}
                currentUserId={currentUserId}
              >
                <AntdButton>Escalate Review</AntdButton>
              </AntdModalTrigger>}
              <div className="submission-actions-div">
                <SubmissionActions
                  isMostRecentSubmission={isMostRecentSubmission}
                  showMarkAppraisal={
                    (canShowConsumerDelivery &&
                        send_borrower_appraisal &&
                        !is_amc_lender &&
                        canSeeSubmissionTab)
                  }
                  showSendAppraisal={
                    (canShowConsumerDelivery &&
                        send_borrower_appraisal &&
                        !is_amc_lender &&
                        canSeeSubmissionTab)
                  }
                  showRequestRecon={
                    (checkPermission('submission_request_reconsideration') &&
                        orderData.job_type !== 'review' &&
                        orderData.job_type !== 'va')
                  }
                  showRequestRevision={
                    (checkPermission('revisions_create_edit')) && this.props.flags?.plat1860EnableMultiStepRevision
                  }
                  showRunReviewEngine={
                    (this.canViewReggoraReview() && this.reviewEngineEnabled())
                  }
                  hasPreviousReviews={
                    (this.getTargetReview())
                  }
                  compareAppraisalPdfs={this.props.compareAppraisalPdfs}
                  currentUserId={currentUserId}
                  is_amc_lender={is_amc_lender}
                  loan={loanData}
                  mailedByInformation={orderData.mailed_by_info_list}
                  onHide={onHide}
                  order={order}
                  orderData={orderData}
                  previousSubmission={this.props.previousSubmission}
                  refreshOrder={refreshOrder}
                  sub={sub}
                  ucdpEadInfo={this.getUcdpEadInfo()}
                  version={sub.version}
                />
                {auto_send_to_borrower_status &&
                    <Tooltip
                      className='autosend-tooltip'
                      placement='bottomLeft'
                      title={<Rephrase>{auto_send_to_borrower_status}</Rephrase>}
                    ><ExportOutlined className={`${auto_send_to_borrower_status_color} ml-2`} /></Tooltip>}
              </div>
            </div>
          </div>


          {sub.matched_excluded_appraiser_license ? this.getExclusionaryListAlertIcon() : null}

          {showReviewTabs ? <Nav tabs className='mt-3'>
            { this.showWarrantyReviewTab() ? (
              <RepurchaseWarrantyTab
                onClick={() => this.setState({ selectedTab: tabName.RepurchaseWarranty })}
                selectedTab={this.state.selectedTab}
                sub={sub}
              />
            ) : null
            }
            {showReggoraReview ? <NavItem>
              <NavLink className='submission-tab' active={this.state.selectedTab === 'review'} onClick={() => this.setState({ selectedTab: 'review' })}>
                <small>{sub && sub.ucdp_preview && sub.ucdp_preview.pathway_result ? <CheckCircleOutlined className='text-success mr-1' /> : <MinusCircleOutlined className='text-muted mr-1' />} Reggora Review</small>
              </NavLink>
            </NavItem> : null}

            {showUcdpTab ? <NavItem>
              <NavLink className='submission-tab' active={this.state.selectedTab === 'ucdp'} onClick={() => this.setState({ selectedTab: 'ucdp' })}>
                <small>{this.getUcdpIcon(hasUcdpSubmission, hasGSEBlockErrorForUCDP)} UCDP Results </small>
              </NavLink>
            </NavItem> : null}

            {showEadTab ? <NavItem>
              <NavLink className='submission-tab' active={this.state.selectedTab === 'ead'} onClick={() => this.setState({ selectedTab: 'ead' })}>
                <small>{this.getEadIcon(hasEadSubmission, hasGSEBlockErrorForEAD)} EAD Results </small>
              </NavLink>
            </NavItem> : null}
          </Nav> : null}

          {showReviewTabs ? this.getSelectedTab(isEditable) : null}

        </div>

        <div className="card-footer bg-white submission-card-footer">
          <div className='d-flex'>
            {sub.pdf_report && sub.pdf_report.document_url &&
                <div>
                  <AntdModalTrigger
                    modal={{
                      component: PdfViewModal,
                      size: 'full',
                      contentClass: 'pdf-view-modal',
                    }}
                    pdf={{ fileUrl: sub.pdf_report.document_url }}>
                    <AntdButton className="mr-2"><SelectOutlined className='mr-1' /> View PDF</AntdButton>
                  </AntdModalTrigger>
                </div>
            }
            <>
              <SubmissionDownloadActions
                canDownloadPdf={
                  hasViewSubmissionPermission &&
                    !!sub.pdf_report &&
                    !!sub.pdf_report.document_url
                }
                canDownloadXml={
                  hasViewSubmissionPermission &&
                    !!sub.xml_report &&
                    !!sub.xml_report.document_url
                }
                canDownloadInvoice={
                  hasViewSubmissionPermission &&
                    !is_reggora_amc_order &&
                    !is_review_appraiser &&
                    !!sub.invoice &&
                    !!sub.invoice.document_url
                }
                canDownloadAmcInvoice={
                  hasViewSubmissionPermission &&
                    is_reggora_amc_order &&
                    !!sub.lender_invoice &&
                    !is_review_appraiser
                }
                onDownloadPdf={() => downloadFile(sub.pdf_report.document_url, sub.pdf_report.document_name)}
                onDownloadXml={() => downloadFile(sub.xml_report.document_url, sub.xml_report.document_name)}
                onDownloadInvoice={() => downloadFile(sub.invoice.document_url, sub.invoice.document_name)}
                onDownloadAmcInvoice={
                  () => downloadFile(sub.lender_invoice.document_url, sub.lender_invoice.document_name)
                }
              />
              {
                shouldShowExternalReviewDocumentUI &&
                  <ExternalReviewDownload
                    downloadDocument={this.downloadDocument}
                    externalReviewResults={loanData.external_review_results}
                    orderID={orderData.id}
                    submissionVersion={sub.version}
                    classNames='mr-2'
                    showIcon={false}
                    useAntButton={true}
                    buttonColor='white'
                  />
              }
            </>

            {(canShowConsumerDelivery &&
                send_borrower_appraisal &&
                auto_send_borrower_appraisal) &&
                <ModalTrigger
                  component={StopAutoSendAppraisalToBorrowerModal}
                  report={true}
                  orderData={orderData}
                  version={sub.version}
                  refreshOrder={refreshOrder}
                >
                  <AntdButton disabled={sub.auto_send_to_borrower_blocked || sub.auto_sent_to_borrower} className='mr-2'>Stop Auto Send</AntdButton>
                </ModalTrigger>
            }

            {(shouldShowReviewArtifactDownload) &&
                    <AntdButton className="mr-2" onClick={this.downloadReviewArtifact}>
                      <DownloadOutlined className='mr-1' /> Review Artifact
                    </AntdButton>
            }

            { (this.showReggoraReviewTab()) &&
                <ReviewNotesButton orderId={orderData.id} submissionVersion={sub.version} />
            }
          </div>
        </div>

      </div>
    )
  }
}

const mapStateToProps = state => ({
  submissionReviews: state.submissionReviews.submissionReviews,
  reviewNotes: state.submissionReviews.reviewNotes,
  submissionFiles: selectSubmissionFiles(state),
  orderSubmissionReviewRuleSetKey: selectOrderSubmissionReviewRulesetForUser(state),
})

const mapDispatchToProps = { updateMinimumOrder, addVendorScore: addScore }

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  connectResource({
    prefetch: false,
    apiVersion: 2,
    namespace: 'submitUCDP',
    endpoint: 'order/ucdp',
    successMessage: {
      POST: 'Submitted to UCDP',
    },
  }),
  connectResource({
    prefetch: false,
    apiVersion: 2,
    namespace: 'submitEAD',
    endpoint: 'order/ead',
    successMessage: {
      POST: 'Submitted to EAD',
    },
  }),
  connectResource({
    namespace: 'approveReport',
    endpoint: 'order/approve',
    prefetch: false,
    apiVersion: 2,
    successMessage: { POST: 'Your report has been sent!' },
  }),
  connectResource({
    namespace: 'unapproveReport',
    endpoint: 'order/:orderId/unapprove',
    prefetch: false,
    apiVersion: 2,
    successMessage: { POST: 'This report has been unapproved.' },
  }),
  withLDConsumer(),
)(SubmissionObject)
