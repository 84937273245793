export const paymentOptionsToReadable = {
  lender_default: 'Lender\'s Default',
  ordering: 'Send Payment Link to Borrower Before Ordering',
  closing: 'Collect at Close',
  internal: 'Internal Payment',
  on_hold: 'On Hold until Payment',
  branch_card: 'Use Branch Card',
  add_new_card: 'Add Branch Card',
  waiting_for_payment: 'Send Link and Wait for Payment',
  send_link_but_proceed_without_payment: 'Send Payment Request but Proceed With Order',
  deferred: 'Defer Borrower Payment Link',
  broker_payment: 'Broker Payment',
}

export const PAYMENT_TYPES = [
  { value: 'stripe', label: 'Pay via Stripe' },
  { value: 'external', label: 'Paid outside of Reggora platform' },
]
