import React from 'react'
import {
  Button,
  Dropdown,
  Menu,
  Tooltip,
} from 'antd'

import { DownOutlined } from '@ant-design/icons'
import SendBorrowerByMailModalContainer from './SendBorrowerByMailModalContainer'
import SendConsumerModalContainer from './SendConsumerModalContainer'
import ModalTrigger from '../../../common/modals/ModalTrigger'
import AntdModalTrigger from '../../../common/modals/antd/ModalTrigger'
import ModalROVContainer from '../ModalROVContainer'
import RevisionModal from '../RevisionModal'
import ConfirmReviewEngineModal from './ReggoraReview/ConfirmReviewEngine'
import { checkPermission } from '../../../common/utils/helpers'
import { PDFCompareModal } from '@app/components/pdf-compare-viewer'

export default function SubmissionActions(props) {
  const {
    compareAppraisalPdfs,
    currentUserId,
    hasPreviousReviews,
    is_amc_lender,
    loan,
    onHide,
    order,
    orderData,
    refreshOrder,
    showMarkAppraisal,
    showRequestRecon,
    showRequestRevision,
    showRunReviewEngine,
    showSendAppraisal,
    sub,
    ucdpEadInfo,
    isMostRecentSubmission,
  } = props

  const {
    shouldRenderGSESubmitButtons,
    eadDisabled,
    eadText,
    eadPopoverText,
    eadOnClick,
    ucdpDisabled,
    ucdpText,
    ucdpPopoverText,
    ucdpOnClick,
  } = ucdpEadInfo

  const menuRef = React.useRef(null)

  const [isVisible, setIsVisible] = React.useState(false)


  const handleMenuClick = (item) => {
    setIsVisible(false)
    switch (item.key) {
      case 'ucdp-action':
        ucdpDisabled || ucdpOnClick()
        break
      case 'ead-action':
        eadDisabled || eadOnClick()
        break
    }
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      { showMarkAppraisal &&
        <Menu.Item key="mark-appraisal">
          <ModalTrigger
            component={SendBorrowerByMailModalContainer}
            modalClassName='modal-primary'
            loan={loan}
            order={order}
            orderData={orderData}
            version={sub.version}
            currentUserId={currentUserId}
            mailedByInformation={orderData.mailed_by_info_list}
            refreshOrder={refreshOrder}
            onClick={() => setIsVisible(false)}
          >
            <a>
              Mark Appraisal Sent By Mail
            </a>
          </ModalTrigger>
        </Menu.Item>
      }
      { showSendAppraisal &&
        <Menu.Item key="send-appraisal-to-consumer">
          <ModalTrigger
            component={SendConsumerModalContainer}
            report={true}
            loan={loan}
            order={order}
            orderData={orderData}
            version={sub.version}
            modalClassName='modal-primary'
            sentReceipts={orderData.submission_sent_to_consumer}
            refreshOrder={refreshOrder}
          >
            <a>
              Send Appraisal To Consumer Electronically
            </a>
          </ModalTrigger>
        </Menu.Item>
      }
      {
        shouldRenderGSESubmitButtons &&
        <Menu.Item key="ucdp-action">
          {ucdpDisabled ? (
            <Tooltip title={ucdpPopoverText} placement="left">
              <span className="disabled-submission-action">
                {ucdpText}
              </span>
            </Tooltip>
          ) : (
            <span className={ucdpDisabled ? 'disabled-submission-action' : ''}>
              {ucdpText}
            </span>
          )}
        </Menu.Item>
      }
      {
        shouldRenderGSESubmitButtons &&
        <Menu.Item key="ead-action">
          {eadDisabled ? (
            <Tooltip title={eadPopoverText} placement="left">
              <span className="disabled-submission-action">
                {eadText}
              </span>
            </Tooltip>
          ) : (
            <>
              {eadText}
            </>
          )}
        </Menu.Item>
      }
      { showRequestRevision &&
      <Menu.Item key="request-revision">
        <AntdModalTrigger
          modal={{
            component: RevisionModal,
            maskClosable: false,
            size: 'large',
          }}
          order_id={orderData.id}
          refetchOrder={refreshOrder}
          is_amc_lender={is_amc_lender}
          job_type={orderData.job_type}
          submissionVersion={sub.version}
        >
          <a>Request Revision</a>
        </AntdModalTrigger>
      </Menu.Item>
      }
      { showRequestRecon &&
        <Menu.Item key="request-reconsideration">
          <ModalTrigger
            backdrop='static'
            component={ModalROVContainer}
            modalClassName='modal-primary modal-lg'
            onHide={onHide}
            order_id={orderData.id}
            refreshOrder={refreshOrder}
            submission_version={sub.version}
          >
            <a>
              Request Reconsideration of Value
            </a>
          </ModalTrigger>
        </Menu.Item>
      }
      { (showRunReviewEngine && checkPermission('reggora_review_action')) &&
        <Menu.Item key="run-review-engine">
          {isMostRecentSubmission ? (
            <AntdModalTrigger
              modal={{
                component: ConfirmReviewEngineModal,
                maskClosable: true,
                size: 'medium',
              }}
              order_id={orderData.id}
              is_amc_lender={is_amc_lender}
              submission_version={sub.version}
              hasPreviousReviews={hasPreviousReviews}
              onHide={onHide}
              refreshOrder={refreshOrder}
            >
              <a>
                Resubmit to Reggora Review
              </a>
            </AntdModalTrigger>
          ) : (
            <Tooltip title='Only the most recent submission can be resubmitted.' placement="left">
              <span className="disabled-submission-action">
              Resubmit to Reggora Review
              </span>
            </Tooltip>

          )}

        </Menu.Item>
      }
      { compareAppraisalPdfs && <CompareReportToPrevious previousSubmission={props.previousSubmission} sub={sub} /> }
    </Menu>
  )

  React.useEffect(() => {
    function handleClickOutside(event) {
      if (isVisible && menuRef.current && !menuRef.current.contains(event.target)) {
        setIsVisible(false)
      }
    }
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [isVisible, menuRef])

  return (
    <div ref={menuRef}>
      <Dropdown
        overlay={menu}
        placement={'bottomRight'}
        visible={isVisible}
      >
        <Button onClick={() => setIsVisible(!isVisible)}>
          Actions
          <DownOutlined />
        </Button>
      </Dropdown>
    </div>
  )
}

const CompareReportToPrevious = ({ previousSubmission, sub }) => {
  if (sub.version <= 1) { return null }

  const currentReport = sub.pdf_report?.document_url
  const previousReport = previousSubmission?.pdf_report?.document_url

  if (!currentReport || !previousReport) { return null }

  return (
    <Menu.Item key="compare-pdf-to-previous">
      <AntdModalTrigger
        modal={{
          component: PDFCompareModal,
          maskClosable: false,
          size: 'full',
        }}
        currentReport={sub.pdf_report?.document_url}
        previousReport={previousSubmission?.pdf_report?.document_url}
      >
        <a>Compare PDF to Previous</a>
      </AntdModalTrigger>

    </Menu.Item>
  )
}
