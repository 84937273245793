import React, { Component, Fragment } from 'react'
import { compose } from 'redux'
import { convertToUTCDate, utcToLocal } from '../../common/utils/helpers'
import { connectResource } from '../../common/utils/resource'
import NumberInput from '../../common/forms/inputs/NumberInput'
import TextInput from '../../common/forms/inputs/TextInput'
import DatePickerInput from '../../common/forms/inputs/DatePickerInput'
import RadiosInput from '../../common/forms/inputs/RadiosInput'
import { PAYMENT_TYPES } from '../../common/constants/borrowerPaymentOptions'
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  Label,
  FormGroup,
} from 'reactstrap'
import Loader from '../../common/layout/components/Loader'
import { differenceInDays } from 'date-fns'
import { isMoment } from 'moment'

class LegacyCreatePaymentModal extends Component {
  state = {
    amount: this.props.editing ? this.props.payment_amount : this.props.total_payout_due,
    description: this.props.default_description,
    name: this.props.default_recipient_name,
    recipient: this.props.default_recipient,
    payment_type: this.props.default_payment_type,
    date_to_be_sent: this.props.date_to_be_sent ? utcToLocal(this.props.date_to_be_sent) : this.props.default_payment_type === 'stripe' ? new Date() : new Date(),
    manual_status: this.props.default_manual_status,
  };

  onChange = (field, value) => {
    this.setState({
      [field]: value,
    })
  }

  onDateChange = (value) => {
    // Only allow someone to pick a date in the future
    const diff = differenceInDays(value, new Date())
    if (diff >= 0 || this.state.payment_type === 'external') {
      this.setState({
        date_to_be_sent: value,
      })
    }
  }

  componentDidMount = () => {
    // Segment tracking
    const { analytics_track_component, analytics_data } = this.props
    const { firstname, lastname, role, lender } = analytics_data
    analytics_track_component('Opened Modal', {
      user: `${firstname} ${lastname}`,
      role: role.name,
      lender: lender.name,
      component: this.constructor.name,
    })
  }

  getDateTimeToBeSent = (date_to_be_sent) => {
    let dateToSend
    const dateToday = new Date()

    if (isMoment(date_to_be_sent)) {
      date_to_be_sent = date_to_be_sent.toDate()
    }

    const dateSelectedWithoutTime = `${date_to_be_sent.getFullYear()}/${date_to_be_sent.getMonth()}/${date_to_be_sent.getDate()}`
    const dateTodayWithoutTime = `${dateToday.getFullYear()}/${dateToday.getMonth()}/${dateToday.getDate()}`

    if (dateSelectedWithoutTime === dateTodayWithoutTime) {
      dateToSend = dateToday
    } else {
      dateToSend = convertToUTCDate(date_to_be_sent)
    }
    return dateToSend
  }


  onSubmit = () => {
    const {
      paymentEndpoint,
      refreshOrder,
      editing,
      paymentId,
      analytics_track_component,
      analytics_data,
    } = this.props
    const {
      amount,
      description,
      date_to_be_sent,
      payment_type,
      manual_status,
    } = this.state

    const data = {
      amount: (amount * 100),
      description,
      payment_type,
      date_to_be_sent: this.getDateTimeToBeSent(date_to_be_sent),
      manual_status,
    }

    if (editing) {
      // Segment Tracking
      const { firstname, lastname, role, lender } = analytics_data
      analytics_track_component('Submitted An Edit', {
        user: `${firstname} ${lastname}`,
        role: role.name,
        lender: lender.name,
        component: this.constructor.name,
      })

      data.payment_id = paymentId
      data.date_to_be_sent = date_to_be_sent
      paymentEndpoint.put(data).then(refreshOrder)
    } else {
      // Segment Tracking
      const { firstname, lastname, role, lender } = analytics_data
      analytics_track_component('Submitted Create Payment', {
        user: `${firstname} ${lastname}`,
        role: role.name,
        lender: lender.name,
        component: this.constructor.name,
      })
      paymentEndpoint.create(data).then(refreshOrder)
    }
  }

  getManualPaymentStatuses = () => {
    return [
      {
        value: 'unpaid',
        label: 'Unpaid',
      },
      {
        value: 'paid',
        label: 'Paid',
      },
    ]
  }

  canSubmit = (outstandingBalance) => {
    const {
      amount,
      date_to_be_sent,
      payment_type,
    } = this.state

    if (
      (!amount || !date_to_be_sent) ||
      (amount > outstandingBalance) ||
      (Number(amount) === 0) ||
      (payment_type === 'stripe' && amount < 0)
    ) {
      return false
    }

    return true
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  render() {
    const {
      onHide,
      editing,
      paymentEndpoint,
      total_payout_due,
      payment_amount,
      default_payment_type,
      default_manual_status,
    } = this.props
    const {
      amount,
      description,
      payment_type,
      date_to_be_sent,
      manual_status,
    } = this.state

    const currentDate = new Date()

    const submitBtnTxt = editing ? 'Edit Payment' : (
      payment_type !== 'external' ? 'Initiate Transfer' : 'Submit')

    let outstandingBalance = total_payout_due
    if (editing && default_payment_type === 'external' && default_manual_status === 'paid') {
      outstandingBalance += payment_amount
    }

    return (<Fragment>
      {(paymentEndpoint.isLoading) && <Loader />}
      <ModalHeader toggle={onHide}>{editing ? 'Editing ' : 'Creating '}Payment</ModalHeader>
      <ModalBody>
        <Row>
          <Col xs="12">
            <FormGroup>
              <Label htmlFor="name">Payment Description:</Label>
              <TextInput
                placeholder="Description"
                name={'description'}
                value={description}
                onChange={value => this.onChange('description', value)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <FormGroup>
              <Label htmlFor="name">Amount</Label>
              <NumberInput
                placeholder="Amount"
                name={'amount'}
                min={0}
                value={amount}
                onChange={value => this.onChange('amount', value)}
              />
              {Boolean(!amount || Number(amount) === 0) && <span className='text-danger my-2'>Required</span>}
              {Boolean(amount && amount > outstandingBalance) && <span className='text-danger my-2'>
                Transfer amount cannot exceed available balance: ${outstandingBalance.toFixed(2)}
              </span>}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <FormGroup>
              <Label htmlFor="name">Send Date:</Label>
              <DatePickerInput
                value={date_to_be_sent}
                minDate={payment_type === 'stripe' && currentDate}
                startDate={payment_type === 'stripe' && currentDate}
                onChange={value => this.onDateChange(value)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <FormGroup>
              <Label htmlFor="name">Payment Type</Label>
              <RadiosInput
                name={'payment_type'}
                value={payment_type}
                options={PAYMENT_TYPES}
                onChange={value => this.onChange('payment_type', value)}
              />
            </FormGroup>
          </Col>
        </Row>
        {payment_type === 'external' &&
          <Row>
            <Col xs="12">
              <FormGroup>
                <Label htmlFor="name">Payment Status</Label>
                <RadiosInput
                  name={'manual_status'}
                  value={manual_status}
                  options={this.getManualPaymentStatuses()}
                  onChange={value => this.onChange('manual_status', value)}
                />
              </FormGroup>
            </Col>
          </Row>}
        { !editing && payment_type !== 'external' &&
          <Row>
            <Col xs='12'>
              <div className='text-danger text-wrap'>Funds will be transferred to vendor Stripe account on the scheduled day. This transaction is non-reversible.</div>
            </Col>
          </Row>
        }
      </ModalBody>
      <ModalFooter>
        <Button onClick={onHide}>Cancel</Button>
        <Button disabled={!this.canSubmit(outstandingBalance)} color='primary' onClick={this.onSubmit}>{submitBtnTxt}</Button>
      </ModalFooter>
    </Fragment>)
  }
}

export default compose(
  connectResource({
    prefetch: false,
    namespace: 'paymentEndpoint',
    endpoint: 'order/payment/:id?',
    successMessage: {
      POST: 'Your payment has been added.',
      PUT: 'Your payment has been updated.',
    },
    apiVersion: 2,
  }),
)(LegacyCreatePaymentModal)
