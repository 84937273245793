import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import API, { API_VERSION, REQUEST_METHOD } from '../../../api'
import STATUS from '../../../common/constants/reducer_statuses'

export const initialState = {
  availableProductTags: {
    data: [],
    status: STATUS.IDLE,
  },
}

export const fetchAvailableProductTags = createAsyncThunk(
  'product/fetchAvailableProductTags',
  async (_, { rejectWithValue }) => {
    try {
      const response = await API('fee/availableProductTags', API_VERSION.TWO).request(REQUEST_METHOD.GET)
      return response
    } catch (err) {
      return rejectWithValue(err.errors._error)
    }
  },
)

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    resetProductState: () => initialState,
  },
  extraReducers: {
    [fetchAvailableProductTags.pending]: (state) => {
      state.availableProductTags.status = STATUS.LOADING
    },
    [fetchAvailableProductTags.fulfilled]: (state, action) => {
      state.availableProductTags.status = STATUS.SUCCEEDED
      state.availableProductTags.data = action.payload
    },
    [fetchAvailableProductTags.rejected]: (state) => {
      state.availableProductTags.status = STATUS.FAILED
    },
  },
})

export default productSlice.reducer
