import React, { useEffect, useState, useCallback } from 'react'
import { ResultRuleset } from './ResultRuleset'
import { ResultTable } from './ResultTable'
import { selectRepurchaseWarrantySubmissionByVersion, selectRepurchaseWarrantyReviewResultsByVersion, selectRulesets } from '@store/reducers/submissionReviews/submissionReviewsSelectors'
import { useSelector, useDispatch } from 'react-redux'
import { fetchRepurchaseWarrantyReviewResults } from '@store/reducers/submissionReviews/submissionReviewsSlice'
import ReviewNotes from './ReviewNotes'
import { Tag } from 'antd'
import { Alert } from '@common/alerts'
import { RuleBadge } from './RuleBadge'
import { repurchaseWarrantyStatusToTag, WarrantyStatusIcon } from '../../Warranty/OrderWarrantyStatus'
import ReviewDatapoints from './ReviewDatapoints'
import { REPURCHASE_WARRANTY_STATUSES } from '@app/common/constants/warranty'

const DisplayReggoraFindings = (fieldData) => {
  const { desc, assertions, engine_outcome, data_points } = fieldData
  return (
    <>
      <div className="findings-desc">
        {desc}
      </div>
      <ReviewDatapoints {...{ assertions, engine_outcome, data_points }} />
    </>
  )
}

const columns = [
  {
    title: <span className="centered">Status</span>,
    dataIndex: 'fieldData',
    width: 150,
    minWidth: 100,
    render: ({ display_outcome, outcome }) =>
      RuleBadge({ display_outcome, outcome }),
  },
  {
    title: <span>Field(s)</span>,
    dataIndex: 'field',
    minWidth: 150,
    width: 300,
  },
  {
    title: <span>Reggora Findings</span>,
    dataIndex: 'fieldData',
    minWidth: 150,
    render: (fieldData) => DisplayReggoraFindings(fieldData),
  },
]

/**
 * @param {string|undefined} submissionReviewId
 */
const useFetchReviewResults = (submissionReviewId) => {
  const dispatch = useDispatch()
  const [resultsLoading, setResultsLoading] = useState(false)

  const getReviewResults = useCallback(() => {
    if (submissionReviewId) {
      setResultsLoading(true)
      dispatch(fetchRepurchaseWarrantyReviewResults({ result_id: submissionReviewId }))
    }
  }, [dispatch, submissionReviewId])

  useEffect(() => {
    getReviewResults()
  }, [getReviewResults])

  return { resultsLoading, setResultsLoading }
}

const WarrantyStatusBanner = ({ repurchaseWarranty }) => {
  const { messages: rawMessages, status } = repurchaseWarranty || {}
  const messages = rawMessages?.filter(Boolean)
  const color = repurchaseWarrantyStatusToTag[status]
  const messageByStatus = {
    [REPURCHASE_WARRANTY_STATUSES.WARRANTY_GRANTED]: 'This appraisal is qualified for Reggora Warranty',
    [REPURCHASE_WARRANTY_STATUSES.WARRANTY_DECLINED]: 'This appraisal does not qualify for Reggora Warranty',
  }
  const message = messageByStatus[status]

  if (!message) { return null }

  return (
    <div className='repurchase-warranty__banner'>
      <Tag
        className='h5 status-bar d-flex flex-column'
        color={color}
        style={{ padding: '0.5rem 1rem', margin: '0', width: '100%' }}
      >
        <div
          className='status_message'
          data-test-id="warranty-banner"
          style={{ marginBottom: messages?.length > 0 ? '0.5rem' : '0' }}
        >
          <WarrantyStatusIcon status={status} />
          <span className='ml-1'>{message}</span>
        </div>
        {messages?.map((msg, index) => (
          <div data-test-id={`warranty-banner-message-${index + 1}`} key={index}>{msg}</div>
        ))}
      </Tag>
    </div>
  )
}

export function RepurchaseWarranty(props) {
  const {
    isEditable,
    orderData,
    submission,
    submissionVersion,
  } = props

  const [resultData, setResultData] = useState([])
  const rulesets = useSelector((state) => selectRulesets(state))
  const submissionReview = useSelector((state) => selectRepurchaseWarrantySubmissionByVersion(state, submissionVersion))
  const reviewResults = useSelector((state) => selectRepurchaseWarrantyReviewResultsByVersion(state, submissionVersion))
  const [resultRulesetName, setResultRulesetName] = useState({
    name: '',
    key: '',
  })

  const { resultsLoading, setResultsLoading } = useFetchReviewResults(submissionReview?._id)

  useEffect(() => {
    if (rulesets && submissionReview) {
      if (rulesets.length === 0 || submissionReview.review_result?.length === 0) {
        setResultRulesetName({
          name: '',
          key: '',
        })
        return
      }

      const ruleset = rulesets.find(ruleset => ruleset.key === submissionReview.review_result?.[0].rule_set_key)
      if (ruleset) {
        setResultRulesetName({
          name: ruleset.name,
          key: ruleset.key,
        })
        return
      }

      setResultRulesetName({
        name: '',
        key: '',
      })
    }
  }, [rulesets, submissionReview])


  useEffect(() => {
    const parsedRules = reviewResults ? Object.values(reviewResults).map((rule) => {
      return {
        key: rule._id,
        status: {
          ruleName: rule.rule_name,
          engine_outcome: rule.engine_outcome,
        },
        field: rule.fields_message,
        description: rule.lender_message,
        vendor_description: rule.vendor_message,
        fieldData: {
          assertions: rule.assertions,
          data_points: rule?.data_points || [],
          data_points_by_submission: rule?.data_points_by_submission || {},
          desc: rule.lender_message,
          display_outcome: rule.display_outcome,
          engine_outcome: rule.engine_outcome,
          fieldName: rule.fields_message,
          hasSystemOverride: rule.hasSystemOverride,
          id: rule._id,
          notes: rule.note_logs,
          outcome_override_logs: rule.outcome_override_logs,
          outcome: rule.outcome,
          submissionReviewId: rule.submission_review_result_id,
          submissionVersion: submission.version,
        },
      }
    }) : []
    setResultData(parsedRules)
  }, [reviewResults, submission.version])

  useEffect(() => {
    if (reviewResults && resultsLoading) {
      setResultsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewResults, resultsLoading])

  if (!submissionReview) {
    return (
      <div className='submission-tab-content repurchase-warranty__container'>
        <div className='submission-tab-content__header'>
          <div className='mr-3'>
            <Alert data-test-id="warranty-banner" title="This submission has not yet undergone Reggora Warranty" type="waiting" />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='submission-tab-content repurchase-warranty__container'>
      <div className='submission-tab-content__header'>
        <div className='mr-3'>
          <ResultRuleset resultRuleset={resultRulesetName} createdDate={submissionReview.created} />
        </div>
        <div className='flex-grow-1 ml-3'>
          <WarrantyStatusBanner repurchaseWarranty={submission?.repurchase_warranty} />
        </div>
      </div>
      <div className='submission-tab-content__body'>
        <ResultTable columns={columns} data={resultData} isLoading={resultsLoading} />
        <ReviewNotes
          orderId={orderData.id}
          submissionVersion={submissionVersion}
          isEditable={isEditable}>
        </ReviewNotes>
      </div>
    </div>
  )
}
