import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { Button } from 'reactstrap'
import TextArea from 'antd/lib/input/TextArea'
import { selectAssignableUsers, selectFetchAssignableUsersStatus } from '@app/store/reducers/user/userSelectors'
import { fetchAssignableUsers } from '@app/store/reducers/user/usersSlice'
import { ModalHeader, ModalBody, ModalFooter, ModalCloser } from '@app/common/modals/antd/ModalComponents'
import { OverlayLoader } from '@app/common/layout/components/OverlayLoader'
import { useApiRequest } from '@app/common/hooks'
import { setSuccessMessage } from '@app/common/utils/resource'
import STATUS from '@app/common/constants/reducer_statuses'


export default function ReviewEscalationModal(props) {
  const [selectedUser, setSelectedUser] = useState(null)
  const [message, setMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const apiRequest = useApiRequest()
  const assignableUsers = useSelector(selectAssignableUsers)
  const fetchAssignableUsersStatus = useSelector(selectFetchAssignableUsersStatus)

  useEffect(() => {
    dispatch(fetchAssignableUsers(props.orderId))
  }, [dispatch, props.orderId])

  useEffect(() => {
    if ([STATUS.IDLE, STATUS.LOADING].includes(fetchAssignableUsersStatus)) {
      setIsLoading(true)
    } else if ([STATUS.SUCCEEDED, STATUS.FAILED].includes(fetchAssignableUsersStatus)) {
      setIsLoading(false)
    }
  }, [fetchAssignableUsersStatus])

  const onSubmit = async () => {
    setIsLoading(true)
    try {
      const response = await apiRequest({
        endpoint: `order/${props.orderId}/escalate-review`,
        method: 'POST',
        body: {
          user_id: selectedUser.id,
          message,
        },
      })
      dispatch(setSuccessMessage(response.success))
      props.refreshOrder()
    } finally {
      setIsLoading(false)
    }
  }

  return <OverlayLoader isLoading={isLoading}>
    <ModalHeader title='Review Escalation'/>
    <ModalCloser onClose={props.modalApi.cancel} />
    <ModalBody>
      <label>User:</label>
      <Select
        valueKey='id'
        labelKey='fullname'
        placeholder='Select User'
        value={selectedUser}
        onChange={setSelectedUser}
        options={assignableUsers.filter(assignableUser => assignableUser.id !== props.currentUserId)}
      />
      <label className='mt-4'>Message:</label>
      <TextArea
        rows={5}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
    </ModalBody>
    <ModalFooter>
      <Button disabled={!selectedUser} onClick={onSubmit}>Submit</Button>
    </ModalFooter>
  </OverlayLoader>
}
