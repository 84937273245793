/* eslint-disable react/no-unescaped-entities */
import { Alert, ModalHeader, Modal, ModalBody, FormGroup, Col, Row, Label, ModalFooter, Button, Input, UncontrolledTooltip, Nav, NavItem, NavLink, Popover, PopoverHeader, PopoverBody, Badge } from 'reactstrap'
import { Form } from 'react-final-form'
import React, { Fragment, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import moment from 'moment'

import {
  selectFollowUpAllocationData,
} from 'app/store/reducers/order/orderSelectors'
import {
  fetchFollowUpAllocationData,
} from 'app/store/reducers/order/orderSlice'

import {
  CheckCircleOutlined,
  ProfileOutlined,
  TeamOutlined,
  UserOutlined,
  WarningOutlined,
} from '@ant-design/icons'

import {
  Tooltip as AntTooltip,
  InputNumber,
  Input as AntInput,
  Checkbox,
  Badge as AntBadge,
  Alert as AntAlert,
} from 'antd'
import { readableToApi, apiToReadable, checkPermission, alwaysDisableVendorSelection, overrideVendorSelection } from '../../common/utils/helpers'
import { ReactSelectField } from '../../common/forms'
import { valueLoanFile } from '../../common/widgets'
import Table from '../../common/tables/Table'
import Column from '../../common/tables/Column'
import classnames from 'classnames'
import DatePicker from 'react-datepicker'
import NewConsumerForm from './NewConsumerForm'
import { paymentOptionsToReadable } from '../../common/constants/borrowerPaymentOptions'
import ModalInformation from '../../common/modals/ModalInformation'
import {
  SubmissionUpload,
} from 'reggora_shared_ui/components'
import AppraiserNameWithPopover from './AppraiserNameWithPopover'
import SaveCreateOrderButton from './SaveCreateOrderButton'
import SkipButton from './SkipButton'
import Rephrase from '../../common/utils/Rephrase'
import { CURRENCY_FORMATTER, CURRENCY_PARSER } from '../../common/utils/fees'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { JOB_TYPES } from '@app/common/constants/jobTypes'

const Search = AntInput.Search

const checkProductPermission = ({
  is_follow_up,
  productPermissions,
  role,
  productId,
}) => {
  const orderType = is_follow_up ? 'follow_up' : 'primary'
  if (productPermissions[productId]) {
    return productPermissions[productId][
      `${readableToApi(role.name)}_${orderType}_order`
    ]
  } else {
    // If we have a prduct in the list that does not show up in permissions, then that means that it was deletede at some point,
    // so just give the user the ability to edit it if they really want.
    return true
  }
}

const checkJobTypeEditPermission = ({
  job_type,
  is_using_amc,
  is_amc_lender,
  is_follow_up,
  is_reassign_order,
}) => {
  if (
    job_type !== 'va' &&
    !is_using_amc &&
    !is_amc_lender &&
    job_type !== 'amc' &&
    !is_reassign_order &&
    ((!is_follow_up &&
      checkPermission('order_can_edit_assignment_non_followup')) ||
      (is_follow_up &&
        checkPermission('order_can_edit_assignment_on_create_follow_up')))
  ) {
    return true
  }

  return false
}

// sometimes we want to enable the appraiser tab, but disable actual appraiser assignment
// this is why logic for the tab permission and the actual appraiser assignment permission are separated
const appraiserSelectionTabPermitted = ({
  job_type,
  is_follow_up,
  is_reassign_order,
  orderData,
  is_amc_lender,
  integrationMode,
  selected_products,
}) => {
  return (
    ((job_type === 'manually' &&
      !is_follow_up &&
      checkPermission('order_can_edit_assignment_non_followup')) ||
      (job_type === 'manually' &&
        is_follow_up &&
        checkPermission('order_can_edit_assignment_on_create_follow_up')) ||
      (job_type === 'automatically' &&
        is_reassign_order &&
        orderData &&
        orderData.accepted &&
        ['accepted', 'inspection_scheduled'].includes(orderData.statusKey)) ||
      (job_type === 'amc' &&
        is_amc_lender &&
        (!orderData ||
          ['finding_appraisers', 'waiting_for_payment'].includes(
            orderData.statusKey,
          ) ||
          !orderData.accepted || is_reassign_order)) ||
      (integrationMode && orderData)) &&
    selected_products[0] !== null
  )
}

const createTooltip = (text, target) => (
  <UncontrolledTooltip placement="top" target={target}>
    {text}
  </UncontrolledTooltip>
)

const getRemoveButton = ({
  removeAppraiserHandler,
  upArrowHandler,
  downArrowHandler,
  id,
  item,
  disableAppraiserSelection,
}) => {
  return disableAppraiserSelection ? (
    ''
  ) : (
    <div className="d-flex align-items-center justify-content-around">
      <div
        className="d-inline-block"
        onClick={() => removeAppraiserHandler(id, item)}
      >
        <i
          className="fa fa-minus-square appraiser-remove-button"
          aria-hidden="true"
        ></i>
      </div>
      <div className="d-inline-block overflow-hidden">
        <div
          onClick={() => upArrowHandler(id, item)}
          className="d-flex arrow-container overflow-hidden"
        >
          <i className="fas fa-sort-up arrow-sort"></i>
        </div>
        <div
          onClick={() => downArrowHandler(id, item)}
          className="d-flex align-items-end arrow-container overflow-hidden"
        >
          <i className="fas fa-sort-down arrow-sort"></i>
        </div>
      </div>
    </div>
  )
}

const getAppraiserOverrideWarning = ({ selected_products, numProducts }) => {
  const message = 'You have selected a product that has vendor level pricing. The order fee may vary depending on which vendor accepts the order.'
  const styling = selected_products[0] && numProducts > 0 ? '' : 'mt-3'

  return (
    <div className={styling}>
      <AntAlert
        message={message}
        banner
      />
    </div>
  )
}

const getJobAllocationMessage = (job_type) => {
  // We only return these 2 job types from the defaultAllocationMode endpoint
  let job_type_str = 'Manual'
  if (job_type === 'automatically') {
    job_type_str = 'Automatic'
  }

  return `Job Allocation Type: ${job_type_str}`
}

const getJobAllocatingWarning = ({ job_type, selected_products }) => {
  const product = selected_products[0]
  const showAutomaticAllocationMessage = job_type === 'automatically' && product && product.use_for_default_follow_up_behavior
  let message = getJobAllocationMessage(job_type)
  if (showAutomaticAllocationMessage) {
    message = `${message} to ${product.follow_up_appraiser}`
  }
  const styling = 'mt-3'

  return (
    <div className={styling}>
      <AntAlert
        message={message}
        type="info"
      />
    </div>
  )
}

const determineProductOptions = ({
  products,
  selected_products,
  editing,
  is_follow_up,
  productPermissions,
  role,
  selectIndex,
}) => {
  // determine which products should be shown for the select at selectIndex
  const allProducts = products
  const selectedProducts = selected_products

  // normally, the 0th item allows the user to change job type
  let canSelectDifferentJobType = selectIndex === 0
  let canSelectReviewProducts = true
  let jobTypeToMatch
  if (selectedProducts && selectedProducts[0] && selectedProducts[0].job_type) {
    jobTypeToMatch = selectedProducts[0].job_type
  }

  if (editing) {
    // we need special logic if the user is editing an order
    if (jobTypeToMatch === 'review') {
      // cannot change away from review
      canSelectDifferentJobType = false
    } else {
      // make sure they don't change it to a review order
      canSelectReviewProducts = false
    }
  } else if (is_follow_up) {
    // follow up orders cannot use review products
    canSelectReviewProducts = false
  }

  const userHasReviewOrderPermission = checkPermission('order_create_review')
  if (!userHasReviewOrderPermission) {
    // user doesn't even have the permission to view review products
    canSelectReviewProducts = false
  }

  return allProducts.filter((product) => {
    const hasPermission = checkProductPermission({
      is_follow_up,
      productPermissions,
      role,
      productId: product.id,
    })
    if (!hasPermission) {
      return false
    }

    const productJobType = product.job_type
    if (!canSelectReviewProducts && productJobType === 'review') {
      // they cannot access review products for this order
      return false
    }

    if (!canSelectDifferentJobType && jobTypeToMatch !== productJobType) {
      // they should not be allowed to select a different job type right now
      return false
    }

    // we passed the checks, so this product is fine
    return true
  })
}

const checkForAppraiserOverrides = (selected_products) => {
  let appraiserOverridesExist = false

  selected_products.forEach(product => {
    if (product && (product.appraiser_overrides || product.has_appraiser_overrides)) {
      appraiserOverridesExist = true
    }
  })
  return appraiserOverridesExist
}

export default function ModalCreateOrder(props) {
  const {
    dontSayAppraiser,
    useNewPanelistSearch,
  } = useFlags()
  const {
    is_follow_up,
    productPermissions,
    role,
    job_type,
    is_using_amc,
    is_amc_lender,
    is_reassign_order,
    orderData,
    integrationMode,
    selected_products,
    editing,
    outOfAppraisersAutomatic,
    checkValidity,
    selectedAppraisers,
    xmlFile,
    pdfFile,
    removeAppraiserHandler,
    upArrowHandler,
    downArrowHandler,
    products,
    handleAutoAcceptInput,
    auto_accept_conditions,
    product_amount,
    reggora_fee,
    additional_fees,
    additionalProductFees,
    selectedLoan,
    priority,
    onOrderDetailChange,
    order_request_method,
    filterToggleHandler,
    selectedFilters,
    filterInputHandler,
    cancelFilterHandler,
    applyFilterHandler,
    handleVisibleChange,
    activeTab,
    popoversVisible,
    closePopovers,
    due_date,
    appraiser_due_date,
    toggleTab,
    productSelected,
    use_integration_fee,
    onSubmit,
    onSaveConsumer,
    onHide,
    loansWithoutStatus,
    handleLoanChange,
    review_appraiser_choice,
    reviewAppraiserOptions,
    outOfAppraisersManual,
    editProductAmount,
    removeProduct,
    addProduct,
    consumer_payment_style,
    hasAdditionalFees,
    loadingProducts,
    showAdditionalFeesForm,
    isMissingFields,
    onAdditionalFeeChange,
    onAdditionalFeeRemove,
    onAdditionalFeeAdd,
    searchInputText,
    onSearchSubmission,
    clearSearchInput,
    onSearchInputChange,
    originalOrder,
    resetFiltersHandler,
    appliedFilters,
    showPaymentOptions,
    consumerPaymentOptions,
    availableAppraisers,
    addAllAppraisersHandler,
    checkIfAlreadySelected,
    addAppraiserHandler,
    sortAppraisers,
    getIndexById,
    removeAllAppraisersHandler,
    appraiser_acceptance_method,
    offer_parameters,
    onCombinatorChange,
    onSkip,
    onPDFChange,
    onPDFRemove,
    onXMLChange,
    onXMLRemove,
    loanIdForEncompass,
    isChained,
    maxCascadeAssignments,
    maxActiveNonPendingAssignments,
    disableMultiProductSelection,
  } = props

  const dispatch = useDispatch()
  const [primaryContactNeeded, setPrimaryContactNeeded] = useState(false)
  const followUpAllocationData = useSelector(selectFollowUpAllocationData)

  const primaryOrderId = originalOrder?.id
  const selectedProductId = selected_products[0]?.id

  useEffect(() => {
    // fetch follow up allocation info when product changes
    if (is_follow_up && primaryOrderId) {
      dispatch(fetchFollowUpAllocationData({
        primaryOrderId,
        selectedProductId,
      }))
    }
  }, [
    is_follow_up,
    primaryOrderId,
    selectedProductId,
    dispatch,
  ])

  const fieldToInput = {
    due_date: <DatePicker
      className="order-date-input form-control"
      onChange={(value) => handleAutoAcceptInput(value, 'due_date')}
      selected={auto_accept_conditions.due_date ? moment(auto_accept_conditions.due_date) : moment()}
    />,
    fee: <Row className='m-0'>
      <Input className="order-date-input mr-2" style={{ width: '45%' }} onChange={({ target: { value } }) => handleAutoAcceptInput(value, 'fee')} type='number' step={0.01} value={auto_accept_conditions.fee}/>
      <Select
        className='w-50'
        name='fee_modifier'
        valueKey='value'
        labelKey='label'
        value={auto_accept_conditions.fee_modifier}
        onChange={({ value }) => handleAutoAcceptInput(value, 'fee_modifier')}
        options={[{ label: 'Fixed', value: 'fixed' }, { label: '%', value: 'percent' }]}
        clearable={false} />
    </Row>,
  }

  let totalFee = 0
  totalFee = product_amount ? totalFee + parseFloat(product_amount) : totalFee
  if (reggora_fee) { totalFee += parseFloat(reggora_fee) }
  if (additional_fees) {
    for (const additionalFee of additional_fees) {
      if (!isNaN(parseFloat(additionalFee.amount))) {
        totalFee = totalFee + parseFloat(additionalFee.amount)
      }
    }
  }

  let totalAdditionalProductsFee = 0
  additionalProductFees.forEach(fee => {
    totalAdditionalProductsFee += fee.amount / 100
  })
  totalFee += totalAdditionalProductsFee

  if (!Number.isInteger(totalFee)) {
    totalFee = totalFee.toFixed(2)
  }

  let disableAppraiserSelection = false
  const isEditing = editing && orderData && !is_reassign_order

  const disableWhileEditing = isEditing && (!orderData.can_change_requested_appraiser || orderData.on_hold)

  const disableAMC = job_type === 'amc' && !is_amc_lender

  const disableIntegration = (integrationMode && orderData && !is_reassign_order) && !['waiting_for_payment', 'pending_approval'].includes(orderData.statusKey)

  const disableReview = job_type === 'review'

  if (
    disableWhileEditing ||
    disableAMC ||
    disableReview ||
    disableIntegration
  ) { disableAppraiserSelection = true }

  const submitted = orderData && ['submitted'].indexOf(orderData.statusKey) > -1

  // Set bool that will disable all the fields that Reassign Order should not be able to touch
  let disableForReassign = false
  if (is_reassign_order) {
    disableForReassign = true
  }

  let title = ''
  if (editing) {
    let address = ''
    if (orderData && orderData.subject_property) {
      const property = orderData.subject_property
      if (property.property_address_full) {
        address = property.property_address_full
      }
      if (property.property_address_city) {
        address += `, ${property.property_address_city}`
      }
      if (property.property_address_state) {
        address += `, ${property.property_address_state}`
      }
      if (property.property_address_zip) {
        address += ` ${property.property_address_zip}`
      }
    } else if (orderData && orderData.manual_subject_property) {
      const property = orderData.manual_subject_property
      if (property.property_address_full) {
        address = property.property_address_full
      }
      if (property.property_address_city) {
        address += `, ${property.property_address_city}`
      }
      if (property.property_address_state) {
        address += `, ${property.property_address_state}`
      }
      if (property.property_address_zip) {
        address += ` ${property.property_address_zip}`
      }
    } else {
      const loan = orderData.loan_file
      if (loan.subject_property_address) {
        address = loan.subject_property_address
      }
      if (loan.subject_property_city) {
        address += `, ${loan.subject_property_city}`
      }
      if (loan.subject_property_state) {
        address += `, ${loan.subject_property_state}`
      }
      if (loan.subject_property_zip) {
        address += ` ${loan.subject_property_zip}`
      }
    }
    if (is_reassign_order) {
      title = `Reassign Order ${address}`
    } else {
      title = `Edit Order ${address}`
    }
  } else {
    let address = ''
    if (selectedLoan) {
      if (selectedLoan.subject_property_address) {
        address = selectedLoan.subject_property_address
      }
      if (selectedLoan.subject_property_city) {
        address += `, ${selectedLoan.subject_property_city}`
      }
      if (selectedLoan.subject_property_state) {
        address += `, ${selectedLoan.subject_property_state}`
      }
      if (selectedLoan.subject_property_zip) {
        address += ` ${selectedLoan.subject_property_zip}`
      }
    }
    if (is_follow_up) {
      title = `Follow-up Order ${address}`
    } else {
      title = `New Order ${address}`
    }
  }

  const priorityRadioButtons = (
    <div>
      <div className="form-check">
        <Input
          disabled={disableForReassign}
          className="form-check-input"
          name={'priority'}
          checked={priority === 'Normal'}
          onChange={(value) => onOrderDetailChange('priority', value.target.value)}
          value={'Normal'}
          type="radio"
        />
        <label className="form-check-label order-form-radio" htmlFor="defaultCheck1">
      Normal
        </label>
      </div>
      <div className="form-check">
        <Input
          disabled={disableForReassign}
          className="form-check-input"
          name={'priority'}
          checked={priority === 'Rush'}
          onChange={(value) => onOrderDetailChange('priority', value.target.value)}
          value={'Rush'}
          type="radio"
        />
        <label className="form-check-label order-form-radio" htmlFor="defaultCheck1">
      Rush
        </label>
      </div>
    </div>
  )

  const orderRequestMethodRadioButtons = (
    <div>
      <div className="form-check">
        <Input className="form-check-input" name={'order_request_method'} checked={order_request_method === 'broadcast'} onChange={(value) => onOrderDetailChange('order_request_method', value.target.value)} value={'broadcast'} type="radio"/>
        <label className="form-check-label order-form-radio" htmlFor="defaultCheck1">
          Broadcast
        </label>
      </div>
      <div className="form-check">
        <Input className="form-check-input" name={'order_request_method'} checked={order_request_method === 'individually'} onChange={(value) => onOrderDetailChange('order_request_method', value.target.value)} value={'individually'} type="radio"/>
        <label className="form-check-label order-form-radio" htmlFor="defaultCheck1">
          Individually
        </label>
      </div>
      {job_type === JOB_TYPES.AMC && is_amc_lender && (
        <div className="form-check">
          <Input className="form-check-input" name={'order_request_method'} checked={order_request_method === 'cascade'} onChange={(value) => onOrderDetailChange('order_request_method', value.target.value)} value={'cascade'} type="radio"/>
          <label className="form-check-label order-form-radio" htmlFor="defaultCheck1">
            Cascade
          </label>
        </div>
      )}
    </div>
  )

  const geoFilterContent = (
    <div id='geo-filter-container' className='pb-0 pt-2 pl-2'>
      <div>
        <Checkbox checked={selectedFilters.radiusSearchSelected} name='radiusSearchSelected' onChange={filterToggleHandler}>Radius Search:</Checkbox>
      </div>
      <div>
        <Input type='number' min={0} disabled={!selectedFilters.radiusSearchSelected} className='d-inline mt-2 col-4' name='radiusDistance' value={selectedFilters.radiusSearchSelected ? selectedFilters.radiusDistance : ''} onChange={filterInputHandler}/><span className='filter-text pl-2'>Miles</span>
      </div>
      <span className='button-footer'>
        <Button className='footer-button' onClick={cancelFilterHandler} color='danger'>Cancel</Button>
        <Button className='footer-button' onClick={applyFilterHandler} color='primary' disabled={!checkPermission('override_default_proximity_allocation')}>Apply</Button>
      </span>
    </div>
  )

  const declinedAppraisersContent = (
    <div id='declined-appraisers-button' className='pb-0 pt-2 pl-2'>
      <div>
        <Checkbox checked={selectedFilters.hideDeclinedAppraisersSelected} name='hideDeclinedAppraisersSelected' onChange={filterToggleHandler}><Rephrase>Hide appraisers that have previously declined this order</Rephrase></Checkbox>
      </div>
      <span className='button-footer'>
        <Button className='footer-button' onClick={cancelFilterHandler} color='danger'>Cancel</Button>
        <Button className='footer-button' onClick={applyFilterHandler} color='primary'>Apply</Button>
      </span>
    </div>
  )

  const specializationFilterContent = (
    <div id='specialization-container' className='pb-0 pt-2 pl-2'>
      <div className='mb-2'>
        <Checkbox checked={selectedFilters.specialization_fha} name='specialization_fha' onChange={filterToggleHandler}>FHA Certification</Checkbox>
      </div>
      <div className='mb-2'>
        <Checkbox checked={selectedFilters.licenseCertifiedGeneral} name='licenseCertifiedGeneral' onChange={filterToggleHandler}>Certified General Real Property Appraiser</Checkbox>
      </div>
      <div className='mb-2'>
        <Checkbox checked={selectedFilters.licenseCertifiedResidential} name='licenseCertifiedResidential' onChange={filterToggleHandler}>Certified Residential Real Property Appraiser</Checkbox>
      </div>
      <div className='mb-2'>
        <Checkbox checked={selectedFilters.licenseCertified} name='licenseCertified' onChange={filterToggleHandler}>Licensed Real Property Appraiser</Checkbox>
      </div>
      <span className='button-footer'>
        <Button className='footer-button' onClick={cancelFilterHandler} color='danger'>Cancel</Button>
        <Button className='footer-button' onClick={applyFilterHandler} color='primary'>Apply</Button>
      </span>
    </div>
  )


  const appraisalStatsContent = (
    <div id='appraisal-stats-container'>
      <div className='pb-0 pt-2 pl-2 filter-text'>
        <Checkbox checked={selectedFilters.turnTimeSelected} name='turnTimeSelected' onChange={filterToggleHandler}>Turn Time:</Checkbox>
        <div className='mt-2 mb-4 d-flex justify-content-center align-items-center'>
          <div className='p-0 col-4 d-flex justify-content-start align-items-center'>
            <Input type='number' min={0} max={1000} className='col-8 mr-1' disabled={!selectedFilters.turnTimeSelected} name='turnTimeLower' value={selectedFilters.turnTimeSelected ? selectedFilters.turnTimeLower : ''} onChange={filterInputHandler}/>
            <span className=''>days</span>
          </div>
          <div className='col-2 pl-2'>to</div>
          <div className='p-0 col-4 d-flex justify-content-start align-items-center'>
            <Input type='number' min={0} max={1000} className='col-8 mr-1' disabled={!selectedFilters.turnTimeSelected} name='turnTimeUpper' value={selectedFilters.turnTimeSelected ? selectedFilters.turnTimeUpper : ''} onChange={filterInputHandler}/>
            <span className=''>days</span></div>
        </div>
        <Checkbox checked={selectedFilters.rejectRateSelected} name='rejectRateSelected' onChange={filterToggleHandler}>Revision Rate:</Checkbox>
        <div className='mt-2 mb-4 d-flex justify-content-center align-items-center'>
          <div className='p-0 col-4 d-flex justify-content-start align-items-center'>
            <Input type='number' min={0} max={100} className='col-8 mr-1' disabled={!selectedFilters.rejectRateSelected} name='rejectRateLower' value={selectedFilters.rejectRateSelected ? selectedFilters.rejectRateLower : ''} onChange={filterInputHandler}/>
            <span className=''>%</span>
          </div>
          <div className='col-2 pl-2'>to</div>
          <div className='p-0 col-4 d-flex justify-content-start align-items-center'>
            <Input type='number' min={0} max={100} className='col-8 mr-1' disabled={!selectedFilters.rejectRateSelected} name='rejectRateUpper' value={selectedFilters.rejectRateSelected ? selectedFilters.rejectRateUpper : ''} onChange={filterInputHandler}/>
            <span className=''>%</span></div>
        </div>
        <Checkbox checked={selectedFilters.onTimeRateSelected} name='onTimeRateSelected' onChange={filterToggleHandler}>On-Time Rate:</Checkbox>
        <div className='mt-2 mb-4 d-flex justify-content-center align-items-center'>
          <div className='p-0 col-4 d-flex justify-content-start align-items-center'>
            <Input type='number' min={0} max={100} className='col-8 mr-1' disabled={!selectedFilters.onTimeRateSelected} name='onTimeRateLower' value={selectedFilters.onTimeRateSelected ? selectedFilters.onTimeRateLower : ''} onChange={filterInputHandler}/>
            <span className=''>%</span>
          </div>
          <div className='col-2 pl-2'>to</div>
          <div className='p-0 col-4 d-flex justify-content-start align-items-center'>
            <Input type='number' min={0} max={100} className='col-8 mr-1' disabled={!selectedFilters.onTimeRateSelected} name='onTimeRateUpper' value={selectedFilters.onTimeRateSelected ? selectedFilters.onTimeRateUpper : ''} onChange={filterInputHandler}/>
            <span className=''>%</span></div>
        </div>
      </div>
      <span className='button-footer'>
        <Button className='footer-button' onClick={cancelFilterHandler} color='danger'>Cancel</Button>
        <Button className='footer-button' onClick={applyFilterHandler} color='primary'>Apply</Button>
      </span>
    </div>
  )

  const showBranchPanel = useNewPanelistSearch && (selectedLoan.branch?.id || selectedLoan.branch_id) && !is_amc_lender
  const showPreferredPanel = useNewPanelistSearch && orderData && orderData.preferred_panel?._id && is_amc_lender

  const panelistFilterContent = (
    <div id='appraisal-panel-source-container' className='pb-0 pt-2 pl-2'>
      {showBranchPanel && (
        <div className="form-check">
          <Input
            id='panel-source-ours-input'
            className="form-check-input"
            name={'panelSource'}
            checked={selectedFilters.panelSource === 'branch'}
            onChange={filterInputHandler}
            value={'branch'}
            type="radio"
          />
          <label className="form-check-label order-form-radio" htmlFor='panel-source-ours-input'>
            Branch Panel
          </label>
        </div>
      )}
      {showPreferredPanel && (
        <div className="form-check">
          <Input
            id='panel-source-ours-input'
            className="form-check-input"
            name={'panelSource'}
            checked={selectedFilters.panelSource === 'preferred'}
            onChange={filterInputHandler}
            value={'preferred'}
            type="radio"
          />
          <label className="form-check-label order-form-radio" htmlFor='panel-source-ours-input'>
            Preferred Panel
          </label>
        </div>
      )}
      <div className="form-check">
        <Input id='panel-source-ours-input' className="form-check-input" name={'panelSource'} checked={selectedFilters.panelSource === 'ourPanel'} onChange={filterInputHandler} value={'ourPanel'} type="radio"/>
        <label className="form-check-label order-form-radio" htmlFor='panel-source-ours-input'>
          {useNewPanelistSearch ? 'Lender Panel' : 'Our Panel'}
        </label>
      </div>
      {!is_amc_lender && (
        <div className="form-check">
          <Input id='panel-source-reggora-input' className="form-check-input" name={'panelSource'} checked={selectedFilters.panelSource === 'reggoraPanel'} onChange={filterInputHandler} value={'reggoraPanel'} type="radio"/>
          <label className="form-check-label order-form-radio" htmlFor='panel-source-reggora-input'>
            Reggora Network
          </label>
        </div>
      )}
      <span className='button-footer'>
        <Button className='footer-button' onClick={applyFilterHandler} color='primary'>Apply</Button>
        <Button className='footer-button' onClick={cancelFilterHandler} color='danger'>Cancel</Button>
      </span>
    </div>
  )

  const appraiserCoverageContent = (
    <div id='appraiser-coverage-container' className='pb-0 pt-2 pl-2'>
      <Checkbox checked={selectedFilters.appraiserCoverageApplied} name='appraiserCoverageApplied' onChange={filterToggleHandler}><Rephrase>Only show appraisers for which the subject property is within their coverage area</Rephrase></Checkbox>
      <span className='button-footer'>
        <Button className='footer-button' onClick={applyFilterHandler} color='primary'>Apply</Button>
        <Button className='footer-button' onClick={cancelFilterHandler} color='danger'>Cancel</Button>
      </span>
    </div>
  )

  const naBadge = (
    <h6><Badge color="secondary">N/A</Badge></h6>
  )

  // The first integration company is the one that we will create a warning for
  let selectedCompany = null
  if (selectedAppraisers && selectedAppraisers.length > 0) {
    for (let i = 0; i < selectedAppraisers; i++) {
      if (selectedAppraisers[i].is_integration) {
        selectedCompany = selectedAppraisers[i]
        break
      }
    }
  }


  let showAppraiserOverrideWarning = false

  if (checkForAppraiserOverrides(selected_products) && order_request_method !== 'broadcast') {
    showAppraiserOverrideWarning = true
  }


  // LOGIC FOR THE ALLOCATION MODE EDIT IN THE SIDEBAR
  let readableAllocationMode
  let onJobTypeEditClick
  let jobTypeEditDisabledWarning
  let jobTypePopover
  let jobTypeEditButtonClasses = 'edit-icon far fa-edit fa-disabled'
  if (is_reassign_order) {
    jobTypeEditDisabledWarning = 'Job Allocation Mode cannot be changed for Reassign Orders'
  }
  if (job_type === 'automatically') {
    readableAllocationMode = 'Automatic'
  } else if (job_type === 'amc') {
    readableAllocationMode = 'Reggora'
    jobTypeEditDisabledWarning = 'Job Allocation Mode cannot be changed for Reggora Products'
  } else if (job_type === 'va') {
    readableAllocationMode = 'VA Portal'
    jobTypeEditDisabledWarning = 'Job Allocation Mode cannot be changed for VA Loans'
  } else if (job_type === 'review') {
    readableAllocationMode = 'Review'
    jobTypeEditDisabledWarning = 'Job Allocation Mode cannot be changed for Reggora Review Products'
  } else {
    readableAllocationMode = 'Manual'
  }

  if (
    checkJobTypeEditPermission({
      job_type,
      is_using_amc,
      is_amc_lender,
      is_follow_up,
      is_reassign_order,
    }) &&
    !disableAppraiserSelection
  ) {
    onJobTypeEditClick = () => handleVisibleChange('jobTypePopover')
    jobTypeEditButtonClasses = 'edit-icon far fa-edit'
  }

  if (activeTab === '1' && jobTypeEditDisabledWarning) {
    jobTypePopover = (<UncontrolledTooltip placement="top" target="job-type-popover">
      {jobTypeEditDisabledWarning}
    </UncontrolledTooltip>)
  } else {
    const jobTypeSelectableOptions = [
      { readable: 'Automatic', value: 'automatically' },
      { readable: 'Manual', value: 'manually' },
    ]
    const jobTypeRadioButtons = (<div>
      {jobTypeSelectableOptions.map(jobTypeOption => (
        <div className="form-check" key={`job-type-option-${jobTypeOption.value}`}>
          <Input disabled={disableAppraiserSelection} className="form-check-input" name={'job_type'} checked={job_type === jobTypeOption.value} onChange={(value) => onOrderDetailChange('job_type', value.target.value)} value={jobTypeOption.value} type="radio"/>
          <label className="form-check-label order-form-radio" htmlFor="defaultCheck1">
            {jobTypeOption.readable}
          </label>
        </div>
      ))}
    </div>)

    jobTypePopover = (<Popover placement="right" isOpen={popoversVisible.jobTypePopover} target="job-type-popover" toggle={closePopovers}>
      <PopoverHeader>Select Job Type</PopoverHeader>
      <PopoverBody>{jobTypeRadioButtons}</PopoverBody>
    </Popover>)
  }

  const sidebarAllocationMode = (<Row sm="3">
    <div className="callout callout-info">
      <small className="text-muted">Job Allocation Mode</small>
      <br />
      <strong className="h4">{readableAllocationMode}</strong>
      <i id='job-type-popover' onClick={onJobTypeEditClick} className={jobTypeEditButtonClasses}></i>
      {jobTypePopover}
    </div>
  </Row>)


  const navIconStyle = { fontSize: 17, marginRight: 7 }


  // LOGIC FOR SIDEBAR ORDER FEE
  let sidebarFee = null
  const showFeeForJobType = job_type !== 'review'
  if (showFeeForJobType) {
    const displayFee = <strong className="h4">${totalFee}</strong>

    sidebarFee = (<Row sm="3">
      <div className="callout callout-info">
        <small className="text-muted">Total Fee</small>
        <br />
        {displayFee}
      </div>
    </Row>)
  }


  // LOGIC FOR THE SIDEBAR ORDER REQUEST METHOD
  let requestMethodEditButton = null
  let requestMethodEditPopover = null
  let sidebarOrderRequestMethod = null
  const showableForAMC = job_type === 'amc' && is_amc_lender
  const showableForJobType = !['amc', 'review', 'va'].find(type => type === job_type)
  if (showableForAMC || showableForJobType) {
    if (!disableAppraiserSelection && checkPermission('order_can_change_request_method')) {
      requestMethodEditButton = (<i id='order-request-method-popover' onClick={() => handleVisibleChange('orderRequestMethodPopover')} className="edit-icon far fa-edit"></i>)
      requestMethodEditPopover = (
        <Popover
          placement="right"
          isOpen={popoversVisible.orderRequestMethodPopover}
          target="order-request-method-popover"
          toggle={closePopovers}
        >
          <PopoverHeader>Select Order Request Method</PopoverHeader>
          <PopoverBody>{orderRequestMethodRadioButtons}</PopoverBody>
        </Popover>
      )
    }

    sidebarOrderRequestMethod = (<Row sm="3">
      <div className='callout callout-info'>
        <small className="text-muted">Order Request Method</small>
        <br />
        <strong className="h4">{apiToReadable(order_request_method)}</strong>
        {requestMethodEditButton}
        {requestMethodEditPopover}
      </div>
    </Row>)
  }

  // LOGIC FOR SIDEBAR DUE DATE AND PRIORITY
  let sidebarDueDate
  let sidebarPriority
  const showDueDateForJobType = !['va', 'review'].find(type => type === job_type)
  if (showDueDateForJobType) {
    const disableForAMC = (((job_type === 'amc' || is_using_amc) && !is_amc_lender) && priority !== 'Rush')
    const disableEditDueDate = disableForAMC || submitted || disableForReassign || !checkPermission('order_can_edit_due_date')
    const editDueDate = (
      <FormGroup className="mb-1">
        <DatePicker className='order-date-input form-control lender-due-date' onChange={(value) => onOrderDetailChange('due_date', value)} selected={due_date ? moment(due_date) : moment()} />
      </FormGroup>
    )
    const displayDueDate = (<strong className="h4">{due_date.format('MM/DD/YYYY')}</strong>)

    const editAppraiserDueDate = (
      <FormGroup className="mb-1">
        <DatePicker className='order-date-input form-control appraiser-due-date' onChange={(value) => onOrderDetailChange('appraiser_due_date', value)} selected={appraiser_due_date ? moment(appraiser_due_date) : moment()} />
      </FormGroup>
    )

    const showAppraiserDueDate = is_amc_lender && job_type === 'amc'

    sidebarDueDate = (<Row sm="3">
      <div className="callout callout-info">
        <small className="text-muted">{showAppraiserDueDate ? 'Lender ' : ''}Due Date</small>
        <br />
        { disableEditDueDate ? displayDueDate : editDueDate }
        {showAppraiserDueDate && (
          <React.Fragment>
            <small className="text-muted">Appraiser Due Date</small>
            <br />
            {editAppraiserDueDate}
          </React.Fragment>
        )}
      </div>
    </Row>)

    let priorityEditButton
    let priorityEditPopover
    if (!disableForReassign) {
      priorityEditButton = (<i id='priority-popover' onClick={() => handleVisibleChange('priorityPopover')} className="edit-icon far fa-edit"></i>)
      priorityEditPopover = (<Popover placement="right" isOpen={popoversVisible.priorityPopover} target="priority-popover" toggle={closePopovers}>
        <PopoverHeader>Select Priority</PopoverHeader>
        <PopoverBody>{priorityRadioButtons}</PopoverBody>
      </Popover>)
    }

    sidebarPriority = (<Row sm="3">
      <div className={priority === 'Normal' ? 'callout callout-info' : 'callout callout-danger'}>
        <small className="text-muted">Priority</small>
        <br />
        <strong className="h4">{priority}</strong>
        {priorityEditButton}
        {priorityEditPopover}
      </div>
    </Row>)
  }

  // SOME TOOLTIP LOGIC
  const appraiserSelectionTarget = 'appraiserSelectionTab'
  let appraiserSelectionTooltipText
  let appraiserSelectionTabTooltip
  if (job_type === 'automatically') {
    appraiserSelectionTooltipText = 'Change Job Allocation Mode to "Manual" to manually select appraisers'
  } else if (job_type === 'va') {
    appraiserSelectionTooltipText = 'Cannot select appraiser through VA integration'
  } else if (job_type === 'amc') {
    appraiserSelectionTooltipText = 'Select a non-Reggora product to manually select appraisers'
  } else if (job_type === 'review') {
    appraiserSelectionTooltipText = 'Review Products will not be assigned to any appraisers'
  } else if (selected_products[0] === null) {
    appraiserSelectionTooltipText = 'Please select a product before selecting appraisers'
  }

  if (appraiserSelectionTooltipText) {
    appraiserSelectionTabTooltip = createTooltip(appraiserSelectionTooltipText, appraiserSelectionTarget)
  }

  const broadcastSettingsTarget = 'broadcastTab'
  const cascadeSettingsTarget = 'cascadeTab'
  let broadcastSettingsTooltipText
  let broadcastSettingsTabTooltip
  let cascadeSettingsTabTooltip
  const cascadeSettingsTooltipText = 'Change order request method to \'Cascade\' to have assignment cascade to other vendors on counter offer creation.'
  if (job_type === 'review') {
    broadcastSettingsTooltipText = 'Review Products cannot be broadcast because they will never be assigned to appraisers'
  } else if (order_request_method !== 'broadcast') {
    broadcastSettingsTooltipText = 'Change Order Request method to "Broadcast" to deliver the order to multiple appraisers at once'
  }

  if (broadcastSettingsTooltipText) {
    broadcastSettingsTabTooltip = createTooltip(broadcastSettingsTooltipText, broadcastSettingsTarget)
  }
  if (is_amc_lender) {
    cascadeSettingsTabTooltip = createTooltip(cascadeSettingsTooltipText, cascadeSettingsTarget)
  }

  // REVIEW TAB LOGIC
  let reviewTabNavItem
  let reviewTabContent
  if (job_type === 'review') {
    const reviewTabName = 'review'
    const isReviewTabActive = activeTab === reviewTabName

    let reviewTabClasses = 'nav_flex'
    if (isReviewTabActive) {
      reviewTabClasses += ' active'
    }

    reviewTabNavItem = (
      <NavItem className={'loan-order-tabs p-0 mr-1'}>
        <NavLink className={reviewTabClasses} onClick={() => toggleTab(reviewTabName)}>
          <CheckCircleOutlined style={navIconStyle} />
          <small>Review Settings</small>
        </NavLink>
      </NavItem>
    )

    if (isReviewTabActive) {
      // we customize the instructions depending on situation
      let uploadSentence = 'You may upload an appraisal now or at any time from the order page.'
      if (editing) {
        if (orderData && orderData.submissions && orderData.submissions.length) {
          uploadSentence = 'You may upload an additional submission now or at any time later through this Edit modal.'
        } else {
          uploadSentence = 'You may upload the first submission now or at any time later through this Edit modal.'
        }
      }
      reviewTabContent = (
        <Col xs="12">
          <Row className='mt-2'>
            <div className='mt-3'><Rephrase>All submissions on this order will be run through Reggora review. {uploadSentence} Please remember Reggora review rules are only run on PDF files.</Rephrase></div>
            <SubmissionUpload
              className='w-100 d-flex justify-content-around my-5'
              pdfRequired={false}
              xmlRequired={false}
              {...{
                onPDFChange,
                onPDFRemove,
                onXMLChange,
                onXMLRemove,
                pdfFile,
                xmlFile,
              }}
            />
          </Row>
        </Col>
      )
    }
  }


  // product options differ for the select at index 0 vs the others
  let productOptionsZero = []
  let productOptionsOther = []
  if (productPermissions) {
    productOptionsZero = determineProductOptions({
      products,
      selected_products,
      editing,
      is_follow_up,
      productPermissions,
      role,
      selectIndex: 0,
    })
    productOptionsOther = determineProductOptions({
      products,
      selected_products,
      editing,
      is_follow_up,
      productPermissions,
      role,
      selectIndex: 1,
    })
  }

  // determine suggestons for vendor on follow up orders
  let recommendedFollowUp = selected_products[0]?.follow_up_appraiser
  let recommendedVendorName = originalOrder?.accepted ? originalOrder.accepted.panelist_name : 'n/a'
  if (is_follow_up && followUpAllocationData) {
    // use info from backend for recommended vendor info
    recommendedFollowUp = followUpAllocationData.follow_up_behavior
    if (followUpAllocationData.same_panelist) {
      recommendedVendorName = followUpAllocationData.same_panelist.panelist_name
    }
  }

  const getProductAmount = (product) => {
    let amount = 0

    if (product && product.external_fee && use_integration_fee) {
      amount = product.external_fee
    } else if (product) {
      amount = product.amount
    }
    return amount
  }

  const renderSelectPrimaryContactStep = (props, submitting, values) => {
    return (
      <Fragment>
        <ModalHeader toggle={onHide}> {title} {'>'} Primary Contact</ModalHeader>
        <ModalBody className="primary-contact-cards">
          <NewConsumerForm
            {...props}
          />
        </ModalBody>
        <ModalFooter className="border-0">
          <SkipButton onSkip={onSubmit} />
          <SaveCreateOrderButton
            key='order-save-create-primary-contact'
            isReassignOrder={is_reassign_order}
            editing={editing}
            outOfAppraisersAutomatic={outOfAppraisersAutomatic}
            checkValidity={checkValidity}
            selectedProducts={selected_products}
            selectedAppraisers={selectedAppraisers}
            jobType={job_type}
            xmlFile={xmlFile}
            pdfFile={pdfFile}
            submitting={submitting}
            primaryContactButton={true}
            values={values}
          />
        </ModalFooter>
      </Fragment>
    )
  }

  const renderCreateOrderModal = (submitting) => {
    let availableAppraisersText = null
    if (useNewPanelistSearch) {
      const panelSource = selectedFilters.panelSource
      if (panelSource === 'branch') {
        availableAppraisersText = 'Panel Source: Branch'
      } else if (panelSource === 'preferred') {
        availableAppraisersText = 'Panel Source: Preferred'
      } else if (panelSource === 'ourPanel') {
        availableAppraisersText = 'Panel Source: Lender'
      }
    }
    return (
      <Fragment>
        <ModalHeader toggle={onHide}>{title}</ModalHeader>
        <ModalBody>
          <Row>
            {/* Left Side Bar */}
            <div className="order-modal-left-column pl-5 pr-5 border-right">
              {sidebarFee}
              {!selectedLoan.is_va_loan && sidebarDueDate}
              {sidebarAllocationMode}
              {sidebarOrderRequestMethod}
              {!selectedLoan.is_va_loan && sidebarPriority}
            </div>
            {/* Main Order Container */}
            <Col className="px-4">
              {/* Tabs */}
              <Nav tabs>
                <NavItem className={'loan-order-tabs p-0 mr-1'}>
                  <NavLink
                    className={classnames({
                      active: activeTab === '1',
                      nav_flex: true,
                    })}
                    onClick={() => {
                      toggleTab('1')
                    }}
                  >
                    <ProfileOutlined style={navIconStyle} />
                    <small>Product & Fees</small>
                  </NavLink>
                </NavItem>
                {reviewTabNavItem}
                {!is_using_amc && (
                  <NavItem
                    className={
                      appraiserSelectionTabPermitted({
                        job_type,
                        is_follow_up,
                        is_reassign_order,
                        orderData,
                        is_amc_lender,
                        integrationMode,
                        selected_products,
                      })
                        ? 'loan-order-tabs'
                        : 'greyed-out loan-order-tabs p-0 mr-1'
                    }
                  >
                    <div>
                      <div id={appraiserSelectionTarget}>
                        <NavLink
                          className={classnames({
                            active: activeTab === '2',
                            nav_flex: true,
                          })}
                          onClick={() => {
                            toggleTab('2')
                          }}
                          disabled={
                            !appraiserSelectionTabPermitted({
                              job_type,
                              is_follow_up,
                              is_reassign_order,
                              orderData,
                              is_amc_lender,
                              integrationMode,
                              selected_products,
                            })
                          }
                        >
                          <div>
                            <UserOutlined style={navIconStyle} />
                            <small><Rephrase>Appraiser Selection</Rephrase></small>
                          </div>
                        </NavLink>
                      </div>
                      <Rephrase>{appraiserSelectionTabTooltip}</Rephrase>
                    </div>
                  </NavItem>
                )}
                {(!is_using_amc ||
                  (job_type === 'amc' && is_amc_lender)) && (
                  <NavItem
                    className={
                      order_request_method === 'broadcast'
                        ? 'loan-order-tabs'
                        : 'greyed-out loan-order-tabs p-0 mr-1'
                    }
                  >
                    <div>
                      <div id={broadcastSettingsTarget}>
                        <NavLink
                          className={classnames({
                            active: activeTab === '3',
                            nav_flex: true,
                          })}
                          onClick={() => {
                            toggleTab('3')
                          }}
                          disabled={
                            order_request_method !== 'broadcast' ||
                            job_type === 'va' ||
                            (job_type === 'amc' && !is_amc_lender)
                          }
                        >
                          <div>
                            <TeamOutlined style={navIconStyle} />
                            <small>Broadcast Settings</small>
                          </div>
                        </NavLink>
                      </div>
                      <Rephrase>{broadcastSettingsTabTooltip}</Rephrase>
                    </div>
                  </NavItem>
                )}
                {is_amc_lender && (
                  <NavItem
                    className={
                      order_request_method === 'cascade'
                        ? 'loan-order-tabs'
                        : 'greyed-out loan-order-tabs p-0 mr-1'
                    }
                  >
                    <div>
                      <div id={cascadeSettingsTarget}>
                        <NavLink
                          className={classnames({
                            active: activeTab === '4',
                            nav_flex: true,
                          })}
                          onClick={() => {
                            toggleTab('4')
                          }}
                          disabled={order_request_method !== 'cascade'}
                        >
                          <div>
                            <TeamOutlined style={navIconStyle} />
                            <small>Cascade Settings</small>
                          </div>
                        </NavLink>
                      </div>
                      <Rephrase>{cascadeSettingsTabTooltip}</Rephrase>
                    </div>
                  </NavItem>
                )}
              </Nav>

              <Row>
                {/* Product & Fees Container */}

                {activeTab === '1' && (
                  <Col xs="12">
                    {selectedLoan ? null : (
                      <FormGroup row className="mb-1">
                        <Col xs="12">
                          <Label htmlFor="select">Loan file</Label>
                          <ReactSelectField
                            name="loan"
                            valueKey={'id'}
                            labelKey={'number'}
                            placeholder={'Please select'}
                            options={
                              loansWithoutStatus
                                ? loansWithoutStatus.data
                                : []
                            }
                            valueComponent={valueLoanFile}
                            optionRenderer={(option) => (
                              <span>
                                #{option.number} - {option.address}{' '}
                                {option.city}, {option.state}
                              </span>
                            )}
                            onChange={handleLoanChange}
                            required
                          />
                        </Col>
                      </FormGroup>
                    )}
                    {is_amc_lender && (
                      <FormGroup
                        row
                        className="mb-2 create-order-select-address"
                      >
                        <Col xs="12">
                          <h6><Rephrase>Select a review appraiser:</Rephrase></h6>
                          <Select
                            name="review_appraiser_choice"
                            valueKey="id"
                            labelKey="full_name"
                            placeholder="Select a review appraiser"
                            value={review_appraiser_choice}
                            onChange={(value) =>
                              onOrderDetailChange(
                                'review_appraiser_choice',
                                value.id,
                              )
                            }
                            options={
                              reviewAppraiserOptions &&
                              reviewAppraiserOptions.data
                                ? reviewAppraiserOptions.data.map(
                                  (item) => {
                                    return {
                                      ...item,
                                      full_name:
                                          item.firstname +
                                          ' ' +
                                          item.lastname,
                                    }
                                  },
                                )
                                : []
                            }
                            clearable={false}
                          />
                        </Col>
                      </FormGroup>
                    )}
                    {/* Out of Appraisers Alert */}
                    {outOfAppraisersAutomatic && (
                      <Alert className="mt-3 mb-0" color="danger">
                        <Rephrase>
                          We were not able to assign your order to an
                          appraiser, either due to your order specifications
                          or your appraisal panelist's coverage areas. Please
                          modify your order and retry Automatic Assignment or
                          change your order to Manual Assignment
                        </Rephrase>
                      </Alert>
                    )}
                    {outOfAppraisersManual && (
                      <Alert className="mt-3 mb-0" color="danger">
                        <Rephrase>
                          None of your appraisers accepted this order, either
                          because they manually declined it or because your
                          time limit was reached. Consider requesting new
                          appraisers, trying Automatic Assignment, or
                          increasing your time limit and retrying with the
                          same appraisers.
                        </Rephrase>
                      </Alert>
                    )}
                    {productPermissions && (
                      <FormGroup row className="mb-2">
                        <Col xs="8" className="my-2 pt-4 pt-4-w pl-3 pr-0">
                          <h6 className="mb-1">Select a Product:</h6>
                          {selected_products.map((product, key) => {
                            let hasPermission = true

                            if (product) {
                              hasPermission = checkProductPermission({
                                is_follow_up,
                                productPermissions,
                                role,
                                productId: product.id,
                              })
                            }
                            return (
                              <div key={key} className="row">
                                <div
                                  className="col-9"
                                >
                                  <Select
                                    name="product"
                                    data-testid="product-select"
                                    labelKey="description"
                                    valueKey="id"
                                    className="mb-0 flex-1"
                                    value={
                                      product &&
                                      product.description &&
                                      product
                                    }
                                    // If the user doesnt have permissions to use a product, and they are editing an order that has
                                    disabled={
                                      (product && !hasPermission) ||
                                      disableForReassign
                                    }
                                    clearable={false}
                                    onChange={(value) => {
                                      productSelected(value, key)
                                    }}
                                    // If we are on our second product option, only allow them to select a product that matches in type.
                                    // Also filter out the products that a user may not have the proper permissions to use
                                    options={
                                      key === 0
                                        ? productOptionsZero
                                        : productOptionsOther
                                    }
                                    required
                                  />
                                </div>
                                {!['review'].includes(job_type) && checkPermission('payments_can_edit_payment_amount_on_order') && (
                                  <div className="col-2 p-0 d-flex align-items-center order-modal-product-amount">
                                    <InputNumber
                                      disabled={
                                        selected_products[key] === null ||
                                          (selected_products[key]
                                            .external_fee &&
                                            use_integration_fee) ||
                                          disableForReassign
                                      }
                                      size="large"
                                      value={getProductAmount(product)}
                                      formatter={CURRENCY_FORMATTER}
                                      parser={CURRENCY_PARSER}
                                      onChange={(val) =>
                                        editProductAmount(val, key)
                                      }
                                      min={0}
                                    />
                                  </div>
                                )}
                                {key > 0 && hasPermission && (
                                  <i
                                    className="fa fa-minus-circle fa-2x text-muted col-1 mt-2 pl-1"
                                    aria-hidden="true"
                                    onClick={(e) => removeProduct(key)}
                                  ></i>
                                )}
                              </div>
                            )
                          })}
                          {!disableMultiProductSelection &&
                            selected_products[0] &&
                            products.length > 0 &&
                            !integrationMode &&
                            (job_type !== 'va') &&
                            !disableForReassign && (
                            <Button
                              className="my-2 mb-3 btn-sm btn-light"
                              type="button"
                              onClick={addProduct}
                            >
                                Add Another Product
                            </Button>
                          )}
                          {showAppraiserOverrideWarning &&
                            getAppraiserOverrideWarning({
                              selected_products,
                              numProducts: products.length,
                            })}

                          {/* Additional Product Fees */}
                          {additionalProductFees &&
                            <div className="mt-2 mb-2">
                              {additionalProductFees && additionalProductFees.map(fee => {
                                return (
                                  <Col key={fee.description}>
                                    <Row>
                                      <FormGroup className="col-6 pl-0 mb-2">
                                        <AntInput
                                          name="description"
                                          value={fee.description}
                                          size="large"
                                          className="order-modal-fee-description-input"
                                          bordered={false}
                                          readOnly={true}
                                        />
                                      </FormGroup>
                                      <FormGroup className="col-2 p-0 mb-2 ml-1">
                                        <InputNumber
                                          name="amount"
                                          value={fee.amount / 100}
                                          size="large"
                                          bordered={false}
                                          readOnly={true}
                                          formatter={CURRENCY_FORMATTER}
                                          parser={CURRENCY_PARSER}
                                        />
                                      </FormGroup>
                                    </Row>
                                  </Col>
                                )
                              })}
                            </div>
                          }

                          {/* Additional Fees */}
                          {!['review'].includes(job_type) && checkPermission('payments_can_edit_payment_amount_on_order') &&
                      !hasAdditionalFees &&
                      !loadingProducts && (
                            <div className="mb-1">
                              <Button
                                className="my-2 mb-3 btn-sm btn-light"
                                type="button"
                                disabled={disableForReassign}
                                onClick={showAdditionalFeesForm}
                              >
                            + Add Additional Fees
                              </Button>
                            </div>
                          )}
                          {hasAdditionalFees ? (
                            <Col className="mb-1 p-0">
                              <h6 className="d-inline-block">Additional Fees:</h6>
                              {isMissingFields() && (
                                <span>
                                  <AntTooltip title="Please fill out description and amount for each additional fee">
                                    <WarningOutlined className="ml-1" style={{ color: 'red' }} />
                                  </AntTooltip>
                                </span>
                              )}
                              {additional_fees.map((fee, idx) => {
                                return (
                                  <Col key={fee.key}>
                                    <Row>
                                      <FormGroup className="col-6 pl-0 mb-sm-0">
                                        <AntInput
                                          name="description"
                                          value={fee.description}
                                          onChange={(e) =>
                                            onAdditionalFeeChange(fee.key, e.target.name, e.target.value)
                                          }
                                          placeholder="Description"
                                          size="large"
                                          className="order-modal-fee-description-input"
                                        />
                                      </FormGroup>
                                      <FormGroup className="col-2 p-0 ml-1 mb-1 d-flex align-items-center">
                                        <InputNumber
                                          name="amount"
                                          value={fee.amount}
                                          onChange={(newValue) =>
                                            onAdditionalFeeChange(fee.key, 'amount', newValue)
                                          }
                                          size="large"
                                          formatter={CURRENCY_FORMATTER}
                                          parser={CURRENCY_PARSER}
                                        />
                                      </FormGroup>
                                      <Col
                                        sm={{ size: '1', offset: 1 }}
                                        className="pl-0 pr-0 pt-1 m-0 mb-1"
                                      >
                                        <i
                                          className="fa fa-minus-circle fa-2x text-muted col-1 mt-2 pl-1"
                                          aria-hidden="true"
                                          onClick={(e) =>
                                            onAdditionalFeeRemove(idx)
                                          }
                                        ></i>
                                      </Col>
                                    </Row>
                                  </Col>
                                )
                              })}
                              <Button
                                className="my-2 mb-3 btn-sm btn-light"
                                type="button"
                                onClick={onAdditionalFeeAdd}
                              >
                          Add Another Fee
                              </Button>
                            </Col>
                          ) : null}
                        </Col>
                        <Col className="my-2" xs="4">
                          <FormGroup row className="my-4">
                            {showPaymentOptions &&
                              !['review'].includes(job_type) && (
                              <Col xs="12">
                                <h6>Borrower Payment Option:</h6>
                                {consumerPaymentOptions.map((item) => (
                                  <div className="form-check" key={item}>
                                    <Input
                                      disabled={
                                        !checkPermission(
                                          'payments_edit_consumer_payment_style',
                                        ) || disableForReassign
                                      }
                                      className="form-check-input"
                                      name={'consumer_payment_style'}
                                      checked={
                                        consumer_payment_style === item
                                      }
                                      onChange={(value) =>
                                        onOrderDetailChange(
                                          'consumer_payment_style',
                                          value.target.value,
                                        )
                                      }
                                      value={item}
                                      type="radio"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="defaultCheck1"
                                    >
                                      {paymentOptionsToReadable[item]}
                                    </label>
                                  </div>
                                ))}
                                {props.consumer_payment_style === 'broker_payment' &&
                                  <div className='mt-3 text-danger'>Note: Broker accepts full responsibility for the payment.</div>}
                              </Col>
                            )}
                          </FormGroup>
                          {is_follow_up &&
                            getJobAllocatingWarning({
                              job_type,
                              selected_products,
                            })}
                        </Col>
                      </FormGroup>
                    )}
                  </Col>
                )}
              </Row>

              {appraiserSelectionTabPermitted({
                job_type,
                is_follow_up,
                is_reassign_order,
                orderData,
                is_amc_lender,
                integrationMode,
                selected_products,
              }) &&
                activeTab === '2' && (
                <Row className="mt-4">
                  {!disableAppraiserSelection && (
                    <Col className="col-5">
                      <Row>
                        <span className="search-bar-with-clear ml-3">
                          <Search
                            value={searchInputText}
                            placeholder={ dontSayAppraiser ? 'Search Vendors' : 'Search Appraisers' }
                            style={{ width: 250 }}
                            onKeyPress={(e) => {
                              if (e.key === 'Enter') {
                                e.preventDefault()
                              }
                            }}
                            onSearch={onSearchSubmission}
                            onChange={onSearchInputChange}
                            enterButton
                            className={'blue-search-button'}
                          />
                          <span
                            className="clear-search-x"
                            onClick={clearSearchInput}
                          >
                              x
                          </span>
                        </span>
                        <span
                          onClick={resetFiltersHandler}
                          className="mt-2 ml-3 pointer"
                        >
                          <u>Reset all Filters</u>
                        </span>
                        <span className="mt-3 ml-3">
                          {is_follow_up && (
                            <h6>
                                Product recommends{' '}
                              {recommendedFollowUp}
                            </h6>
                          )}
                          {is_follow_up && (
                            <h6>
                              <Rephrase>Original Appraiser:</Rephrase>{' '}
                              {recommendedVendorName}
                            </h6>
                          )}
                        </span>
                      </Row>
                    </Col>
                  )}

                  {!disableAppraiserSelection && (
                    <Col className="col-7 d-flex justify-content-end flex-wrap">
                      <span>
                        <Button
                          id="geo-filter-button"
                          className="mr-2 mb-2 filter-button-width"
                          onClick={() =>
                            handleVisibleChange('geoFilterPopover')
                          }
                          color={
                            appliedFilters.geoFiltersApplied
                              ? 'primary'
                              : 'light'
                          }
                        >
                            Geography
                        </Button>
                        <Popover
                          placement="bottom"
                          isOpen={popoversVisible.geoFilterPopover}
                          target="geo-filter-button"
                          toggle={closePopovers}
                        >
                          <PopoverHeader>
                              Select Geography Filters
                          </PopoverHeader>
                          <PopoverBody>{geoFilterContent}</PopoverBody>
                        </Popover>
                      </span>

                      {(isEditing || is_reassign_order) && (
                        <span>
                          <Button
                            id="declined-appraisers-button"
                            className="mr-2 mb-2 filter-button-width"
                            onClick={() =>
                              handleVisibleChange(
                                'declinedAppraisersPopover',
                              )
                            }
                            color={
                              appliedFilters.hideDeclinedAppraisersSelected
                                ? 'primary'
                                : 'light'
                            }
                          >
                              Declined
                          </Button>
                          <Popover
                            placement="bottom"
                            isOpen={
                              popoversVisible.declinedAppraisersPopover
                            }
                            target="declined-appraisers-button"
                            toggle={closePopovers}
                          >
                            <PopoverHeader>
                              <Rephrase>Select Declined Appraiser Filter</Rephrase>
                            </PopoverHeader>
                            <PopoverBody>
                              {declinedAppraisersContent}
                            </PopoverBody>
                          </Popover>
                        </span>
                      )}

                      <span>
                        <Button
                          id="specialization-button"
                          className="mr-2 mb-2 filter-button-width"
                          onClick={() =>
                            handleVisibleChange(
                              'specializationFilterPopover',
                            )
                          }
                          color={
                            appliedFilters.specializationFiltersApplied
                              ? 'primary'
                              : 'light'
                          }
                        >
                            Specializations
                        </Button>
                        <Popover
                          id="specialization-filter-popover"
                          className="width-355"
                          placement="bottom"
                          isOpen={
                            popoversVisible.specializationFilterPopover
                          }
                          target="specialization-button"
                          toggle={closePopovers}
                        >
                          <PopoverHeader>
                              Select Specialization Filters
                          </PopoverHeader>
                          <PopoverBody>
                            {specializationFilterContent}
                          </PopoverBody>
                        </Popover>
                      </span>
                      <span>
                        <Button
                          id="appraisal-stats-button"
                          className="mr-2 mb-2 filter-button-width"
                          onClick={() =>
                            handleVisibleChange('appraisalStatsPopover')
                          }
                          color={
                            appliedFilters.appraisalStatsApplied
                              ? 'primary'
                              : 'light'
                          }
                        >
                          <Rephrase>Appraisal Stats</Rephrase>
                        </Button>
                        <Popover
                          id="appraisal-stats-popover"
                          className="width-355"
                          placement="bottom"
                          isOpen={popoversVisible.appraisalStatsPopover}
                          target="appraisal-stats-button"
                          toggle={closePopovers}
                        >
                          <PopoverHeader>
                            <Rephrase>Select Appraisal Stats Filters</Rephrase>
                          </PopoverHeader>
                          <PopoverBody>
                            {appraisalStatsContent}
                          </PopoverBody>
                        </Popover>
                      </span>
                      <span>
                        <Button
                          id="appraisal-panel-source-button"
                          className="mr-2 mb-2 filter-button-width"
                          onClick={() =>
                            handleVisibleChange(
                              'appraisalPanelSourcePopover',
                            )
                          }
                          color={
                            selectedFilters.panelSource === 'reggoraPanel'
                              ? 'primary'
                              : 'light'
                          }
                        >
                            Panel Source
                        </Button>
                        <Popover
                          id="appraisal-panel-source-popover"
                          className="width-355 "
                          placement="bottom"
                          isOpen={
                            popoversVisible.appraisalPanelSourcePopover
                          }
                          target="appraisal-panel-source-button"
                          toggle={closePopovers}
                        >
                          <PopoverHeader>
                            <Rephrase>Select Source of Appraisal Panel</Rephrase>
                          </PopoverHeader>
                          <PopoverBody>
                            {panelistFilterContent}
                          </PopoverBody>
                        </Popover>
                      </span>
                      <span>
                        <Button
                          id="appraiser-coverage-button"
                          className="mr-2 mb-2 filter-button-width"
                          onClick={() =>
                            handleVisibleChange(
                              'appraiserCoveragePopover',
                            )
                          }
                          color={
                            appliedFilters.appraiserCoverageApplied
                              ? 'primary'
                              : 'light'
                          }
                        >
                            Coverage Area
                        </Button>
                        <Popover
                          id="appraiser-coverage-popover"
                          className="width-355"
                          placement="bottom"
                          isOpen={
                            popoversVisible.appraiserCoveragePopover
                          }
                          target="appraiser-coverage-button"
                          toggle={closePopovers}
                        >
                          <PopoverHeader>
                              Select Coverage Area Filter
                          </PopoverHeader>
                          <PopoverBody>
                            {appraiserCoverageContent}
                          </PopoverBody>
                        </Popover>
                      </span>
                    </Col>
                  )}
                  <Row className="pt-4 pt-4-w pl-3 width-100p__ie">
                    {!disableAppraiserSelection && (
                      <Col className="col-6">
                        <Row>
                          <h5 className="pl-4"><Rephrase>Available Appraisers</Rephrase></h5>
                        </Row>
                        {availableAppraisersText && (
                          <Row>
                            <p className="pl-4">{availableAppraisersText}</p>
                          </Row>
                        )}
                        <div className="table-container">
                          <Table
                            className="tableFixHead"
                            size="sm"
                            striped
                            list={{
                              results: availableAppraisers,
                              filters: {
                                data: availableAppraisers,
                                orderBy: (field) =>
                                  sortAppraisers(
                                    field,
                                    'availableAppraisers',
                                  ),
                              },
                            }}
                          >
                            <Column
                              field="panelist_name"
                              title={<Rephrase>Appraiser</Rephrase>}
                              width={'29%'}
                              orderable="desc"
                              format={(panelist_name, appraiser) =>
                                <AppraiserNameWithPopover
                                  panelistName={panelist_name}
                                  appraiser={appraiser}
                                />
                              }
                            />
                            <Column
                              field="proximity"
                              title={'Proximity'}
                              width={'19%'}
                              orderable="desc"
                              format={(proximity, item) =>
                                proximity && proximity !== 'Infinity' ? (
                                  <span>{proximity.toFixed(2)} mi</span>
                                ) : (
                                  naBadge
                                )
                              }
                            />
                            <Column
                              field="on_time_percentage"
                              title={'On-time'}
                              width={'19%'}
                              orderable="desc"
                              format={(on_time_percentage, item) =>
                                on_time_percentage !== null ? (
                                  <span>
                                    {on_time_percentage.toFixed()}%
                                  </span>
                                ) : (
                                  naBadge
                                )
                              }
                            />
                            <Column
                              field="orders_requiring_revision_percent"
                              title={'Revisions'}
                              width={'19%'}
                              orderable="desc"
                              format={(
                                orders_requiring_revision_percent,
                                item,
                              ) =>
                                orders_requiring_revision_percent ===
                                  null ? (
                                    naBadge
                                  ) : (
                                    <span>
                                      {orders_requiring_revision_percent.toFixed()}
                                      %
                                    </span>
                                  )
                              }
                            />
                            <Column
                              field="id"
                              title={
                                <u
                                  onClick={addAllAppraisersHandler}
                                  className="add-all-link"
                                >
                                  <Col>
                                    <Row className="justify-content-center">
                                        Add
                                    </Row>
                                    <Row className="justify-content-center">
                                        All
                                    </Row>
                                  </Col>
                                </u>
                              }
                              width={'14%'}
                              format={(id, item) => {
                                if (checkIfAlreadySelected(item)) {
                                  return (
                                    <span className="pl-1">
                                      <i
                                        className="fa fa-check-square appraiser-selected-check"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )
                                } else if (
                                  alwaysDisableVendorSelection(item)
                                ) {
                                  return (
                                    <span className="pl-1">
                                      <i
                                        className="fa fa-ban appraiser-selected-disable"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )
                                } else if (
                                  overrideVendorSelection(item)
                                ) {
                                  return (
                                    <span className="pl-1">
                                      <i
                                        className="fa fa-ban appraiser-selected-disable"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )
                                } else {
                                  return (
                                    <span
                                      className="pl-3"
                                      onClick={() =>
                                        addAppraiserHandler(id, item)
                                      }
                                    >
                                      <i
                                        className="fa fa-plus-square appraiser-add-button"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )
                                }
                              }}
                            />
                          </Table>
                        </div>
                      </Col>
                    )}
                    <Col className="col-6">
                      <Row>
                        <h5 className="pl-4">
                          <Rephrase>
                            {disableAppraiserSelection
                              ? 'Selected Appraiser'
                              : 'Selected Appraisers'}
                          </Rephrase>
                        </h5>
                      </Row>
                      {selectedCompany && (
                        <Row className="mr-0 ml-0">
                          <Alert color="warning">
                            <Rephrase>
                              <b>{selectedCompany.panelist_name}</b> uses an
                                external AMC integration, any following
                                selected appraisers will not be requested
                            </Rephrase>
                          </Alert>
                        </Row>
                      )}
                      <div className="table-container">
                        <Table
                          className="tableFixHead"
                          size="sm"
                          striped
                          list={{
                            results: selectedAppraisers,
                            filters: {
                              data: selectedAppraisers,
                              orderBy: (field) =>
                                sortAppraisers(
                                  field,
                                  'selectedAppraisers',
                                ),
                            },
                          }}
                        >
                          <Column
                            field="id"
                            title={''}
                            format={(id, item) => (
                              <AntBadge
                                style={{
                                  backgroundColor: '#fff',
                                  color: '#999',
                                  boxShadow: '0 0 0 1px #d9d9d9 inset',
                                }}
                                count={getIndexById(id) + 1}
                              />
                            )}
                            width={'6%'}
                          />
                          <Column
                            field="panelist_name"
                            title={<Rephrase>Appraiser</Rephrase>}
                            orderable="desc"
                            width={'24%'}
                            format={(panelist_name, item) => {
                              return (
                                <span>
                                  <AppraiserNameWithPopover
                                    panelistName={panelist_name}
                                    appraiser={item}
                                  />
                                </span>
                              )
                            }}
                          />
                          <Column
                            field="proximity"
                            title={'Proximity'}
                            width={'19%'}
                            orderable="desc"
                            format={(proximity, item) =>
                              proximity && proximity !== 'Infinity' ? (
                                <span>{proximity.toFixed(2)} mi</span>
                              ) : (
                                naBadge
                              )
                            }
                          />
                          <Column
                            field="on_time_percentage"
                            title={'On-time'}
                            width={'19%'}
                            orderable="desc"
                            format={(on_time_percentage, item) =>
                              on_time_percentage !== null ? (
                                <span>
                                  {on_time_percentage.toFixed()}%
                                </span>
                              ) : (
                                naBadge
                              )
                            }
                          />
                          <Column
                            field="orders_requiring_revision_percent"
                            title={'Revisions'}
                            width={'19%'}
                            orderable="desc"
                            format={(
                              orders_requiring_revision_percent,
                              item,
                            ) =>
                              orders_requiring_revision_percent ===
                                null ? (
                                  naBadge
                                ) : (
                                  <span>
                                    {orders_requiring_revision_percent.toFixed()}
                                    %
                                  </span>
                                )
                            }
                          />
                          <Column
                            field="id"
                            title={
                              disableAppraiserSelection ? (
                                ''
                              ) : (
                                <div onClick={removeAllAppraisersHandler}>
                                  <u className="remove-all-link">
                                    <Col>
                                      <Row>Remove</Row>All<Row></Row>
                                    </Col>
                                  </u>
                                </div>
                              )
                            }
                            width={'15%'}
                            format={(id, item) =>
                              getRemoveButton({
                                removeAppraiserHandler,
                                upArrowHandler,
                                downArrowHandler,
                                id,
                                item,
                                disableAppraiserSelection,
                              })
                            }
                          />
                        </Table>
                        {disableAppraiserSelection && (
                          <Alert color="warning">
                            <Rephrase>
                              If you would like to reassign a different
                              appraiser to this order, please do so using
                              the Reassign Order button in the Order Actions
                              drop down on the Order Page.
                            </Rephrase>
                          </Alert>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Row>
              )}
              {order_request_method === 'broadcast' && activeTab === '3' && (
                <Col xs="12">
                  <Row className="mt-4">
                    <FormGroup row className="my-4 width-100p__ie">
                      <Col xs="12">
                        <h6><Rephrase>Appraiser Acceptance Method:</Rephrase></h6>
                        <div className="form-check">
                          <Input
                            className="form-check-input"
                            name={'appraiser_acceptance_method'}
                            checked={
                              appraiser_acceptance_method ===
                              'first_come_first_serve'
                            }
                            onChange={(value) =>
                              onOrderDetailChange(
                                'appraiser_acceptance_method',
                                value.target.value,
                              )
                            }
                            value={'first_come_first_serve'}
                            type="checkbox"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="defaultCheck1"
                          >
                            First Come First Serve
                          </label>
                          <br />
                          <small className="text-muted">
                            <Rephrase>
                              The first appraiser to accept the order will be
                              assigned to the order.
                            </Rephrase>
                          </small>
                        </div>
                        <div className="form-check">
                          <Input
                            className="form-check-input"
                            name={'appraiser_acceptance_method'}
                            checked={appraiser_acceptance_method === 'bid'}
                            onChange={(value) =>
                              onOrderDetailChange(
                                'appraiser_acceptance_method',
                                value.target.value,
                              )
                            }
                            value={'bid'}
                            type="checkbox"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="defaultCheck1"
                          >
                            Bid
                          </label>
                          <br />
                          <small className="text-muted">
                            <Rephrase>
                              All selected appraisers will be able to submit a
                              bid for either automated or manual review.
                            </Rephrase>
                          </small>
                        </div>
                      </Col>
                    </FormGroup>
                  </Row>
                  {appraiser_acceptance_method === 'bid' && (
                    <Row>
                      <FormGroup row className="my-2 width-100p__ie">
                        <Col xs="12">
                          <h6>Negotiable Fields:</h6>
                          <small className="text-muted">
                            <Rephrase>
                              Check the fields you would like the appraiser to
                              be able to negotiate.
                            </Rephrase>
                          </small>
                          {['due_date', 'fee'].map((field) => (
                            <div key={field} className="form-check">
                              <Input
                                className="form-check-input"
                                name={field}
                                checked={offer_parameters.includes(field)}
                                onChange={(value) => {
                                  const editable = [...offer_parameters]
                                  const idx =
                                    offer_parameters.indexOf(field)
                                  if (idx > -1) {
                                    editable.splice(idx, 1)
                                  } else {
                                    editable.push(field)
                                  }
                                  onOrderDetailChange(
                                    'offer_parameters',
                                    editable,
                                  )
                                }}
                                value={field}
                                type="checkbox"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="defaultCheck1"
                              >
                                {apiToReadable(field)}
                              </label>
                              <br />
                            </div>
                          ))}
                        </Col>
                      </FormGroup>
                    </Row>
                  )}
                  {appraiser_acceptance_method === 'bid' && (
                    <Row>
                      <FormGroup row className="my-4  width-100p__ie">
                        <Col xs="12">
                          <h6>Auto Accept Conditions:</h6>
                          <small className="text-muted">
                            A bid will be automatically accepted if it meets
                            the set criteria. Empty Fields will not be
                            evaluated
                          </small>
                          {offer_parameters.map((field) => (
                            <div key={field}>
                              <p className="m-0">{apiToReadable(field)}:</p>
                              <Row
                                className="mt-0 mr-0 ml-0"
                                style={{ marginBottom: '10px' }}
                              >
                                {fieldToInput[field]}
                              </Row>
                            </div>
                          ))}
                          <div className="form-check">
                            <Input
                              className="form-check-input"
                              name={'combinator'}
                              checked={
                                auto_accept_conditions.combinator === 'and'
                              }
                              onClick={(value) => onCombinatorChange('and')}
                              value={auto_accept_conditions.combinator}
                              type="radio"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="defaultCheck1"
                            >
                              All Conditions Must Be Met
                            </label>
                          </div>
                          <div className="form-check">
                            <Input
                              className="form-check-input"
                              name={'combinator'}
                              checked={
                                auto_accept_conditions.combinator === 'or'
                              }
                              onClick={(value) => onCombinatorChange('or')}
                              value={auto_accept_conditions.combinator}
                              type="radio"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="defaultCheck1"
                            >
                              If Any Condition Is Met
                            </label>
                          </div>
                        </Col>
                      </FormGroup>
                    </Row>
                  )}
                </Col>
              )}
              {activeTab === '4' && (
                <Col xs="12">
                  <Row className="mt-4">
                    <FormGroup row className="my-4 width-100p__ie">
                      <Col xs="12">
                        <h6><Rephrase>Total Maximum Number of Assignments</Rephrase></h6>
                        <div className="form mt-1 mb-3">
                          <InputNumber
                            name='maxCascadeAssignments'
                            value={maxCascadeAssignments}
                            min={2}
                            max={50}
                            formatter={value => Math.floor(value)}
                            onChange={value => onOrderDetailChange('maxCascadeAssignments', value)}
                          />
                        </div>
                        <h6><Rephrase>Number of Vendors Assigned per Cascade</Rephrase></h6>
                        <div className="form mt-1">
                          <InputNumber
                            name='maxActiveNonPendingAssignments'
                            value={maxActiveNonPendingAssignments}
                            min={1}
                            max={10}
                            formatter={value => Math.floor(value)}
                            onChange={value => onOrderDetailChange('maxActiveNonPendingAssignments', value)}
                          />
                        </div>
                      </Col>
                    </FormGroup>
                  </Row>
                </Col>
              )}
              {reviewTabContent}
              <Row className="w-100"></Row>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="border-0">
          {onSkip ? (
            <SkipButton onSkip={onSkip} />
          ) : (
            <Button onClick={onHide}>Cancel</Button>
          )}
          <SaveCreateOrderButton
            key='order-save-create'
            isReassignOrder={is_reassign_order}
            editing={editing}
            outOfAppraisersAutomatic={outOfAppraisersAutomatic}
            checkValidity={checkValidity}
            selectedProducts={selected_products}
            selectedAppraisers={selectedAppraisers}
            jobType={job_type}
            xmlFile={xmlFile}
            pdfFile={pdfFile}
            submitting={submitting}
          />
        </ModalFooter>
      </Fragment>
    )
  }


  const checkForPrimaryContact = async (props, values) => {
    if (props.hasPrimaryContact || props.ignorePrimaryContactCheck) {
      onSubmit()
    } else if (Object.keys(values).length !== 0) {
      try {
        await onSaveConsumer(values)
        await onSubmit(true)
        onHide()
      } catch (error) {
        // If onSaveConsumer fails, we stop execution and don't call onSubmit
      }
    } else {
      setPrimaryContactNeeded(true)
    }
  }

  if (loanIdForEncompass && selectedLoan.ellie_loan_id && (isChained || (loanIdForEncompass !== selectedLoan.id))) {
    return (
      <Modal isOpen={true}>
        <ModalBody>
          <ModalInformation
            onHide={onHide}
            title="Unable to Create Order"
            body="An order can only be created using the same loan that is opened within Encompass. Please close this file and reopen the correct loan you wish to create an order for."
          />
        </ModalBody>
      </Modal>
    )
  }

  return (
    <Fragment>
      <Form
        onSubmit={(values, form) => checkForPrimaryContact(props, values)}
        render={({ handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit}>
            {primaryContactNeeded
              ? renderSelectPrimaryContactStep(props, submitting, values)
              : renderCreateOrderModal(submitting)}
          </form>
        )}
      />
    </Fragment>
  )
}
