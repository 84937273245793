import React, { useState, useEffect } from 'react'
import { Button } from 'reactstrap'
import { REQUEST_METHOD } from '@app/api'
import { useApiRequest } from '../../../common/hooks'
import Loader from '../../../common/layout/components/Loader'
import ToggleSwitchInput from '../../../common/forms/inputs/ToggleSwitchInput'

const StopEncompassDataSync = ({
  loanId,
  onHide,
}) => {
  const apiRequest = useApiRequest()
  const [isProcessing, setIsProcessing] = useState(false)
  const [loanReplicationStatus, setLoanReplicationStatus] = useState(false)

  useEffect(() => {
    fetchLoanReplicationStatus()
  }, [])

  const fetchLoanReplicationStatus = async () => {
    setIsProcessing(true)
    const endpoint = `loans/encompass/replication/${loanId}`
    const replicationData = await apiRequest({
      endpoint: endpoint,
      method: REQUEST_METHOD.GET,
    })
    setLoanReplicationStatus(replicationData?.loan_replication)
    setIsProcessing(false)
  }

  const onSubmit = async () => {
    setIsProcessing(true)
    const endpoint = `loans/encompass/replication/${loanId}`
    await apiRequest({
      endpoint: endpoint,
      method: REQUEST_METHOD.PUT,
      body: { loan_replication: loanReplicationStatus },
      successMessage: 'Your loan file has been updated.',
    })
    onHide()
  }

  const loader = isProcessing && <Loader />
  return (
    <>
      {loader}
      {!isProcessing && <>
        <div className="d-flex flex-row justify-content-between mt-2">
          <div style={{ fontWeight: 'bold' }}>
            Stop data from being pushed to Encompass:
          </div>
          <div>
            <ToggleSwitchInput
              value={loanReplicationStatus}
              onToggleChange={(value) => setLoanReplicationStatus(value)}
            ></ToggleSwitchInput>
          </div>
        </div>
        <div className="row justify-content-end">
          <Button className="mr-2 mt-2" onClick={onHide}>
            Cancel
          </Button>
          <Button
            className="mr-3 mt-2"
            onClick={onSubmit}
            data-test="submit-button"
            color="primary"
          >
            Save
          </Button>
        </div>
      </>}
    </>
  )
}

export default StopEncompassDataSync
