import React from 'react'
import classNames from 'classnames'

import {
  CheckCircleFilled,
  InfoCircleFilled,
  ExclamationCircleFilled,
  WarningFilled,
  MinusCircleFilled,
  ClockCircleFilled,
} from '@ant-design/icons'

import styles from './Alert.module.css'

const iconMap = {
  success: CheckCircleFilled,
  info: InfoCircleFilled,
  error: ExclamationCircleFilled,
  warning: WarningFilled,
  muted: MinusCircleFilled,
  waiting: ClockCircleFilled,
}

/**
 * @param {{
 *  type?: 'success' | 'info' | 'warning' | 'error' | 'muted' | 'waiting',
 *  title: string,
 *  messages?: string,
 * }} props
 * return {JSX.Element}
 */
export const Alert = ({ type, title, messages, ...props }) => {
  const Icon = iconMap[type]
  const alertClassNames = classNames(styles.alert, {
    [styles[`alert__${type}`]]: type,
  })

  return (
    <>
      <div className={alertClassNames} {...props}>
        <Icon className={styles.alertIcon} />
        <div className={styles.alertTitle}>{title}</div>
        {messages ? <div className={styles.alertMessages}>{messages}</div> : null}
      </div>
    </>
  )
}
