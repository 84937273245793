import React from 'react'

export const APPRAISER_CANCELLATION_REQUEST = 'APPRAISER_CANCELLATION_REQUEST'
export const NO_ACCEPTED_APPRAISERS_AND_NO_DENIED_COMPANIES = 'NO_ACCEPTED_APPRAISERS_AND_NO_DENIED_COMPANIES'
export const NO_ACCEPTED_APPRAISERS_AND_HAS_DENIED_COMPANIES = 'NO_ACCEPTED_APPRAISERS_AND_HAS_DENIED_COMPANIES'
export const VA_PORTAL_ISSUE = 'VA_PORTAL_ISSUE'
export const DEFAULT = 'DEFAULT'
export const SUBMISSION_MATCHED_EXCLUSIONARY_LIST = 'SUBMISSION_MATCHED_EXCLUSIONARY_LIST'
export const FEE_ESCALATION = 'FEE_ESCALATION'
export const COUNTER_OFFER = 'COUNTER_OFFER'
export const NEW_DUE_DATE = 'NEW_DUE_DATE'
export const FEE_ESCALATION_AND_NEW_DUE_DATE = 'FEE_ESCALATION_AND_NEW_DUE_DATE'
export const CONSUMER_DELIVERY_FAILED = 'CONSUMER_DELIVERY_FAILED'
export const SAME_APPRAISER_IS_INVALID = 'SAME_APPRAISER_IS_INVALID'
export const SAME_APPRAISER_ASSIGNED_TO_JUMBO_LOAN_AMP_ORDER = 'SAME_APPRAISER_ASSIGNED_TO_JUMBO_LOAN_AMP_ORDER'
export const LOW_VALUE_APPRAISAL = 'LOW_VALUE_APPRAISAL'
export const INVALID_ZIP_CODE = 'INVALID_ZIP_CODE'
export const REVIEW_ESCALATION = 'REVIEW_ESCALATION'

export const ALERT_TEXT = {
  consumer_submission_download_alerts: 'Your borrower has not downloaded the report sent to them after 72 hours.',
  [APPRAISER_CANCELLATION_REQUEST]: 'Appraiser has requested a cancellation of this order',
  [NO_ACCEPTED_APPRAISERS_AND_NO_DENIED_COMPANIES]: 'No accepted appraiser',
  [NO_ACCEPTED_APPRAISERS_AND_HAS_DENIED_COMPANIES]: 'No accepted appraiser',
  [VA_PORTAL_ISSUE]: 'Issue submitting information to VA portal',
  [DEFAULT]: 'No accepted appraiser',
  [SUBMISSION_MATCHED_EXCLUSIONARY_LIST]: 'Excluded appraiser detected on submission.',
  [FEE_ESCALATION]: "This order's appraiser has requested a fee escalation",
  [COUNTER_OFFER]: "This order's appraiser has submitted a counter offer.",
  [NEW_DUE_DATE]: "This order's appraiser has requested a new due date.",
  [FEE_ESCALATION_AND_NEW_DUE_DATE]: "This order's appraiser has requested a fee escalation and new due date",
  [CONSUMER_DELIVERY_FAILED]: 'Reggora was unable to deliver the appraisal report to the borrower. Please check their email address and manually resend via the Submissions tab.',
  [SAME_APPRAISER_IS_INVALID]: 'The appraiser assigned to this follow up order is no longer valid, please assign another.',
  [SAME_APPRAISER_ASSIGNED_TO_JUMBO_LOAN_AMP_ORDER]: 'The appraiser assigned to this order is already active on another order for the same loan.',
  [LOW_VALUE_APPRAISAL]: 'The appraiser has submitted a low value appraisal.',
  [INVALID_ZIP_CODE]: 'This order or loan has an invalid ZIP code.',
  [REVIEW_ESCALATION]: 'This order has been escalated for review.',
}

const NO_APPRAISER_AUTOMATIC_ORDER_LENDER_ATTENTION_REQUIRED_JSX = (
  <div>
    <p>Requires Attention: Could not find an appraiser to service this order. This may have occurred for the following
       reason(s):
    </p>
    <ul>
      <li>No active appraisers have this zip-code in their coverage area.</li>
      <li>If an order is associated with a branch that uses a strict panel, there may be a smaller group of appraisers
          to pick from.
      </li>
      <li>If a loan has FHA loan type, then Reggora will only assign it to appraisers with an FHA license on file.</li>
    </ul>
  </div>)

export const REGGORA_PROGRESS_ALERT_TEXT = {
  [APPRAISER_CANCELLATION_REQUEST]: 'Appraiser has requested a cancellation of this order',
  [NO_ACCEPTED_APPRAISERS_AND_NO_DENIED_COMPANIES]: NO_APPRAISER_AUTOMATIC_ORDER_LENDER_ATTENTION_REQUIRED_JSX,
  [NO_ACCEPTED_APPRAISERS_AND_HAS_DENIED_COMPANIES]: 'None of the requested appraisers accepted the order',
  [VA_PORTAL_ISSUE]: "This order's data must be edited before it can be submitted to the VA portal",
  [SUBMISSION_MATCHED_EXCLUSIONARY_LIST]: 'We have detected that the appraiser license provided on a recently uploaded submission matches an entry on your exclusionary list.',
  [FEE_ESCALATION]: "This order's appraiser has requested a fee escalation",
  [COUNTER_OFFER]: "This order's appraiser has submitted a counter offer.",
  [NEW_DUE_DATE]: "This order's appraiser has requested a new due date.",
  [FEE_ESCALATION_AND_NEW_DUE_DATE]: "This order's appraiser has requested a fee escalation and new due date",
  [DEFAULT]: 'No accepted appraiser',
  [CONSUMER_DELIVERY_FAILED]: 'Reggora was unable to deliver the appraisal report to the borrower. Please check their email address and manually resend via the Submissions tab.',
  [SAME_APPRAISER_IS_INVALID]: 'The appraiser assigned to this follow up order is no longer valid, please assign another.',
  [SAME_APPRAISER_ASSIGNED_TO_JUMBO_LOAN_AMP_ORDER]: 'The appraiser assigned to this order is already active on another order for the same loan.',
  [LOW_VALUE_APPRAISAL]: 'The appraiser has submitted a low value appraisal.',
  [INVALID_ZIP_CODE]: 'This order or loan has an invalid ZIP code.',
  [REVIEW_ESCALATION]: 'This order has been escalated for review.',
}

export const ESCALATION_ALERT = [
  FEE_ESCALATION,
  COUNTER_OFFER,
  NEW_DUE_DATE,
  FEE_ESCALATION_AND_NEW_DUE_DATE,
]

export const ESCALATION_ALERT_TEXT = {
  [FEE_ESCALATION]: 'fee escalation',
  [COUNTER_OFFER]: 'counter offer',
  [NEW_DUE_DATE]: 'new due date request',
  [FEE_ESCALATION_AND_NEW_DUE_DATE]: 'fee escalation and new due date request',
}

export const DISMISSIBLE_LENDER_ATTENTION_REASONS = [
  VA_PORTAL_ISSUE,
  LOW_VALUE_APPRAISAL,
  SUBMISSION_MATCHED_EXCLUSIONARY_LIST,
  CONSUMER_DELIVERY_FAILED,
  REVIEW_ESCALATION,
]

export const DISMISSABLE_LAR_TOOPTIP = 'Please ensure all necessary actions have been taken before dismissing alert.'
